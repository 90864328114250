import LoginPage from '../views/LoginPage.vue';
import ForgetPasswordPage from '../views/ForgetPasswordPage.vue';
import HomePage from '../views/Home.vue';
import ReservesPage from '../views/ReservesPage.vue';
import SettingPage from '../views/SettingPage.vue';
import CheckRedirectPage from '../views/CheckRedirectPage.vue';
import ProfileSettingPage from '../views/ProfileSettingPage.vue';
import EmailValidationPage from '../views/EmailValidation.vue';
import FormPage from '../views/FormPage.vue';
import Explorer from '../views/Explorer.vue';
import Rule1Page from '../views/rules/rule1.vue';
import Rule2Page from '../views/rules/rule2.vue';
import Rule3Page from '../views/rules/rule3.vue';
import Rule4Page from '../views/rules/rule4.vue';

export default [
  {
    path: '/home',
    component: HomePage,
    name: 'Home',
    needAuth: true,
  },
  {
    path: '/explore',
    component: Explorer,
    name: 'Explore',
    needAuth: true,
  },
  {
    path: '/reserves',
    component: ReservesPage,
    name: 'Reserves',
    needAuth: true,
  },
  {
    path: '/setting',
    component: SettingPage,
    name: 'Setting',
    needAuth: true,
  },
  {
    path: '/rule1',
    component: Rule1Page,
    name: 'Rule1',
  },
  {
    path: '/rule2',
    component: Rule2Page,
    name: 'Rule2',
  },
  {
    path: '/rule3',
    component: Rule3Page,
    name: 'Rule3',
  },
  {
    path: '/rule4',
    component: Rule4Page,
    name: 'Rule4',
  },
  {
    path: '/profile',
    component: ProfileSettingPage,
    name: 'Profile',
    needAuth: true,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/forget-password',
    component: ForgetPasswordPage,
  },
  {
    path: '/email-validation',
    component: EmailValidationPage,
  },
  {
    path: '/payment-result',
    component: () => import('../views/PaymentReview.vue'),
  },
  {
    path: '/form',
    name: 'Form',
    component: FormPage,
  },
  {
    path: '*',
    component: CheckRedirectPage,
  },
];
