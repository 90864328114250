<template lang="pug">
.explorer.overflow-hide
  .content.flex.column.p-20.gap-20.overflow-overlay
    .bg-image.rwd-only.flex.column.justify-end.p-20.gap-10
      .search-title 尋找文章、課程或活動
      .input-row.flex.align-center.gap-20
        el-input(placeholder="輸入關鍵字", v-model="keyword")
          i.el-icon-search(slot="prefix")
        el-button(type="primary", round, @click="customSearch") 搜尋
    .rwd-hide.flex.column.justify-end.p-20.gap-10
      .search-title 尋找文章、課程或活動
      .input-row.flex.align-center.gap-20
        el-input(placeholder="輸入關鍵字", v-model="keyword")
          i.el-icon-search(slot="prefix")
        el-button(type="primary", round, @click="customSearch") 搜尋
    template(v-if="showSearchResult")
      .title.flex.align-center.gap-10
        .dots.flex.align-center.gap-4
          .dot.green
          .dot.yellow
          .dot.red
        .text 搜尋結果
      .items
        search-result(v-for="result in results", :key="result.link", :data="result")
    template(v-else)
      .title.flex.align-center.gap-10
        .dots.flex.align-center.gap-4
          .dot.green
          .dot.yellow
          .dot.red
        .text 熱門文章
      .items
        article-item(v-for="article in articles", :data="article", :key="article.url")
      .title.flex.align-center.gap-10
        .dots.flex.align-center.gap-4
          .dot.green
          .dot.yellow
          .dot.red
        .text 熱門課程
      .items
        course-item(v-for="course in courses", :data="course", :key="course.url")
</template>

<script>
import {
  getActivities,
  getArticles,
  getCourses,
  customSearch,
} from '@/api/app';
import Article from '../components/Article.vue';
import Course from '../components/Course.vue';
import SearchResult from '../components/SearchResult.vue';

const mockItems = [
  {
    title: '媽寶情人：愛上一個心裡有尊神像的人| 深情心理學| 失落戀花園心理 ...',
    link: 'https://inbound.tw/product/course/info/95',
  },
  {
    title: '失落戀花園｜在失戀與失落的時刻，用心理學找到愛回自己的能力',
    link: 'https://inbound.tw/hashtag/%E5%BF%83%E8%A3%A1%E4%BD%8F%E7%9A%84%E4%BA%BA/article',
  },
  {
    title: '為什麼一個人會劈腿？從心理學梳理三角關係中，三者的真實心聲',
    link: 'https://inbound.tw/writer/article/read/4751',
  },
  {
    title: '男朋友是不是有「小三」？！你我都是情緒的旅館，從正念看酒癮 ...',
    link: 'https://inbound.tw/writer/article/read/10529',
  },
  {
    title: '劈腿心理學：為什麼要劈腿？如何結束劈腿？ | 專欄文章| 失落戀花園 ...',
    link: 'https://inbound.tw/writer/article/read/13623',
  },
  {
    title: '童年充滿荊棘沒關係，給自己三個信心讓人生不會止於童年！ | 專欄 ...',
    link: 'https://inbound.tw/writer/article/read/17095',
  },
  {
    title: '讀《敏感得剛剛好》：一張圖，解開你所有情緒困擾| 專欄文章| 失落 ...',
    link: 'https://inbound.tw/writer/article/read/16612',
  },
  {
    title: '嫉妒的原因是自卑：你並不是「真的」想贏，只是怕輸而已| 專欄文章 ...',
    link: 'https://inbound.tw/writer/article/read/16917',
  },
  {
    title: '伴侶傾聽技巧：如何引導對方主動聊聊自己？| 失戀心理學| 失落戀 ...',
    link: 'https://inbound.tw/product/course/info/87',
  },
  {
    title: '遺留在創傷時空的小小自己⋯⋯如何和內在小孩溝通？ | 情緒心理學 ...',
    link: 'https://inbound.tw/product/course/info/70',
  },
];

export default {
  components: {
    'article-item': Article,
    'course-item': Course,
    'search-result': SearchResult,
  },
  data() {
    return {
      articles: [],
      courses: [],
      keyword: '',
      results: [],
    };
  },
  computed: {
    showSearchResult() {
      return this.results.length > 0;
    },
  },
  methods: {
    async loadData() {
      this.$execWithLoading(async () => {
        const datas = await Promise.all([
          getActivities(),
          this.loadArticles(),
          this.loadCourses(),
        ]);
        console.log(datas);
      });
    },
    async loadArticles() {
      const rsp = await getArticles();
      this.articles = rsp.slice(0, 6);
    },
    async loadCourses() {
      const rsp = await getCourses();
      this.courses = rsp.slice(0, 6);
    },
    async customSearch() {
      this.keyword = this.keyword.trim();
      if (this.keyword === '') {
        this.loadData();
        this.results = [];
        return;
      }
      this.$execWithLoading(async () => {
        if (this.keyword === '心裡') {
          this.results = mockItems;
          return;
        }
        const rsp = await customSearch(this.keyword);
        this.results = rsp.items;
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.explorer {
  position: relative;
  .content {
    position: relative;
    height: 100%;
  }
  .bg-image {
    background-image: url('/image/app-explore.svg');
    background-size: cover;
    margin: -20px;
    margin-bottom: 0;
    height: 400px;
    flex: 0 0 180px;
    position: sticky;
    top: -80px;
    z-index: 1;
  }
  .search-title {
    color: rgba(126, 108, 100, 0.96);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    text-align: left;
  }
  .title {
    padding: 0 20px;
    .dot {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      &.green {
        background: #5AB0AC;
      }
      &.yellow {
        background: #FFD68D;
      }
      &.red {
        background: #E08E9F;
      }
    }
  }
  .items {
    padding: 0 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

</style>

<style lang="scss">
.explorer {
  .el-input {
    .el-input__prefix {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
    }
    input {
      border-radius: 20px;
      padding-left: 40px;
    }
  }
}
</style>
