import instance from '@/api/base';

export async function bindLINE(code) {
  const rsp = await instance.put('/v1/line/bind', {
    code,
  });
  return rsp.data;
}

export async function unbindLINE() {
  const rsp = await instance.delete('/v1/line/bind');
  return rsp.data;
}

export async function loginLINE(code) {
  const rsp = await instance.post('/v1/line/login', {
    code,
  });
  return rsp.data;
}
