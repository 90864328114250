<template lang="pug">
.password-page.flex.column.align-center
  .header.flex.align-center
    .logo
  .content.flex.column.align-center.justify-center
    img.form-bg(:src="bgImg")
    .form.flex.column.align-stretch.gap-20(v-if="state === 1")
      .desc.flex.column
        span 無法登入嗎？
        span 我們將會寄 Email 讓您重新設定密碼
      .inputs.flex.column.gap-10
        .input.flex.column
          .title 請輸入您的 Email 帳號
          el-input(v-model="email")
        el-button(type="primary", @click="doForget") 送出
    .form.flex.column.align-stretch.gap-20(v-if="state === 2")
      .desc.flex.column
        span 重新設定密碼的信件已寄出
        span 如果我們有您剛剛輸入的電子郵件的帳戶，您將在收件箱中收到密碼重置說明。
      .desc.flex.column
        span 如果你需要任何幫助，請聯絡我們
    .form.flex.column.align-stretch.gap-20(v-if="state === 3")
      .desc.flex.column
        span 密碼重設
      .inputs.flex.column.gap-10
        .input.flex.column
          .title 請輸入更新密碼
          el-input(v-model="password", type="password")
        el-button(type="primary", @click="doReset") 重新設定密碼
</template>

<script>
import { mapMutations } from 'vuex';
import { forgetPassword, appUpdatePasswordByToken } from '@/api/auth';
import bgImg from '../images/login-bg-img.svg';

export default {
  name: 'forget-password',
  data() {
    return {
      state: 1,
      bgImg,
      email: '',
      password: '',
      token: '',
    };
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    doForget() {
      this.$execWithLoading(async () => {
        await forgetPassword(this.email);
        this.state = 2;
      });
    },
    doReset() {
      this.$execWithLoading(async () => {
        await appUpdatePasswordByToken(this.token, this.password);
        this.$showSuccess('更新密碼成功，請重新登入');
        this.$router.push({ name: 'Login' });
      }, () => {
        this.$showSuccess('已送出更新，請嘗試重新登入');
        this.$router.push({ name: 'Login' });
      });
    },
  },
  mounted() {
    this.setUserInfo(undefined);
    const queryString = window.location.hash.replace('#/forget-password', '');
    const urlParams = new URLSearchParams(queryString);
    this.token = urlParams.get('token');
    if (this.token && this.token !== '') {
      this.state = 3;
    }
  },
};
</script>

<style lang="scss" scoped>
.password-page {
  position: relative;
  .header {
    position: absolute;
    margin-top: 50px;
    width: 100vw;
    .logo {
      margin-left: 40px;
      box-sizing: border-box;
      flex: 0 0 285px;
      background-image: url('/black-logo-long.png');
      background-position: 50% 50%;
      background-size: 285px 45px;
      background-repeat: no-repeat;
      border-right: 1px solid white;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
    }
  }
  .content {
    flex: 1;
    max-width: 820px;
    width: 100vw;
    position: relative;
    .form-bg {
      position: absolute;
      top: calc(50% + 350px);
      right: 160px;
      transform: translate(100%, -100%);
      width: 22%;
      min-width: 160px;
    }
    .form {
      width: calc(100% - 70px);
      max-width: 386px;
      margin: 35px;

      .btn-text {
        margin-top: 3px;
        font-size: 13px;
      }
      .splitter {
        color: #909399;
        .line {
          border-bottom: 1px dashed #909399;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .password-page {
    .header {
      margin-top: 30px;
      .logo {
        margin-left: 35px;
        flex: 0 0 200px;
        background-size: 200px 32px;
      }
    }
  }
}
</style>
