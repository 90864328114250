<template lang="pug">
.rule.flex.column.p-40.gap-20
  i.el-icon-arrow-left.clickable(@click="$router.back()") 返回
  .content.flex.column
    .rule-title {{ title }}
    .content.white-space-pre {{ content }}
</template>

<script>
export default {
  data() {
    return {
      title: '帳號註冊同意書',
      content: `
最新更新日期：2023.12.04
最新版本生效日期：2023.12.04
1. 服務內容及條款之接受
本系統為蛹之生心理諮商所（以下稱「蛹之生」）所開發以視訊方式提供醫療相關專業人員（以下稱「醫療人士」） 與醫療服務及衛生保健知識諮詢者（以下稱「使用者」或「您」）雙方進行線上醫療資源諮詢及衛生教育宣導之應用程式， 以便利之網路及視訊技術媒合醫病或衛生保健知識提供與需求者雙方， 任何時間、任何地點都能即時解答或學習與您密切相關的醫療知識， 讓您對於身體健康相關「知」的權利，不受時間與地域之限制。為維護蛹之生的創立初衷與專業媒合角色之定位，當您使用本系統時，請務必遵守本條款。 請詳細閱讀本使用者政策之條款（以下稱「本條款」），本條款構成具有拘束力之契約內容。當您使用本系統時，表示您已同意本條款。如您不同意本條款，請立即停止使用本系統。
2. 本條款之修改與效力
您同意蛹之生得不定期修改本條款。蛹之生將不定期修改本條款，請多加留意本使用者政策網頁的更新資訊。 除了下列政策以外，另請留意部分產品和功能可能另有相關的附加政策或條款。請查看提供的連結，瞭解其他規範。
3. 使用本系統
歡迎使用蛹之生心理諮商所所經營及維護之系統應用程式。當您使用本系統所提供之服務（以下稱「本服務」），您將受本系統之隱私權條款、本條款及其他任何您使用本服務時可觸及、知悉之額外通知之政策或規則所拘束。若您使用本服務所提供之產品購買服務時，尚需留意相關產品或服務之銷售條款及其額外之政策、條款或通知，其等將對您產生法律上之拘束力。
蛹之生十分仰賴使用者協助我們找出可能違反本條款之使用行為。 如有人向我們檢舉疑似違反本條款之情形，我們會審查相關之應用行為並採取適當行動，包括但不限於禁止使用或移除相關內容，以及限制或終止使用者對各項蛹之生提供之平台的存取權。 蛹之生不擁有或掌控本服務中之使用內容，所有透過本系統進行之活動內容僅限於使用者與醫療人士之間。如醫療人士透過本系統邀請舉辦各項私人活動，該活動內容與本系統官方無關，如有相關爭議及糾紛本系統亦不負責進行補償、 賠償相關責任或退回您所預約之費用或其他支付產品或服務之對價。
4.本系統使用者限制
未滿 7 歲之人不可使用本服務。如您為 7 歲以上未滿 18 歲之人，您需在父母或監護人陪同下使用本服務， 且您的父母及監護人同意受本條款拘束。本系統也禁止曾經遭本系統強制停用本服務之人再為使用本服務。 當您在進行下載、安裝或其他使用本服務之行為時，您聲明並保證：您為年滿 7 歲之人；且如您為 7 歲以上、未滿 20 歲（或您所在國家法律所規定之成年年齡）之人， 您的父母或監護人同意受本條款之拘束；最後，您並非曾遭本服務強制停用之人。
5. 隱私權政策
您的隱私對本系統而言非常重要，為保護及尊重您的隱私權，請參閱我們的《隱私權政策》， 以瞭解我們是如何收集、使用及揭露您的個人資料。
6. 帳號管理
為了創設本系統帳號，您將被請求提供相關資訊，例如您所選擇於使用本服務之帳號或密碼。 您單獨並全權負責保管您所有帳號及密碼之秘密性、防免他人存取您的電子裝置， 及防免他人得以您所有帳號及密碼進行之任何活動。請確保您創設本系統帳號或其他使用本服務所提供為正確、準確及完整之資訊。除非經本系統例外允許，您不可販售、租借、分享或提供您帳號的存取方式給任何其他人， 包括但不限於您收取費用以讓他人透過您的帳號使用本服務。於使用本服務，您同意接收本系統所寄發關於您的帳號之電子通訊，包括寄送電子郵件至您於會員註冊時所提供之電子信箱， 或於本服務上張貼關於您的帳號之公告（例如付費授權、密碼變更、電子信箱或其他交易資訊之確認）。您同意任何本系統以電子方式寄送給您之通知、協議、訊息揭露或其他通訊， 即已符合任何法定通知要件，包括書面通知。您應保留書面影本或電子副本以保存前述電子通知內容。
7. 智慧財產權
著作權：本服務包含所有本服務所提供之內容均受著作權、營業秘密或其他智慧財產權法規及協定之保護。 商標：蛹之生標誌、及其他標記、圖像、標誌、字樣、及聲音均為蛹之生之商標， 而不得加以複製、下載、或以其他方式利用。 本服務擁有張貼或提供之相關資訊（包括但不限於文字、圖片、音檔或影片）之所有權利 （包括但不限於著作權、商標或其他相關權利）或已經他人授權使用，未經蛹之生同意，不得擅自使用前述相關資訊。
8. 服務過程紀錄
蛹之生為了保障使用者與醫療人士基本健康資訊與個資權益，本服務將不進行任何的影音錄製行為。 凡有學術研究、臨床督導、個案研討等需求之醫療人士需進行診療（會談）影音存取， 將會另行與使用者簽署影音檔存取許可同意書，請留意使用本系統以外之視訊存取同意請求。 您知悉本系統將不會主動存取您每次使用本服務之視訊影音檔。 惟在產生上述之需求時，經由雙方（使用者與醫療人士）同意授權，蛹之生之工程人員將才會協助處理。
9. 禁止行為
您同意不違反任何法律、契約、他人智慧財產權，或其他第三人之權利或對他人為任何侵權行為，您須全權負責您在使用本系統時之所有行為。
您同意遵守本條款及本服務相關規則，且您同意不為以下所列禁止行為：
  1. 不得於使用本服務時做出有違善良風俗、猥褻或色情之行為。
  2. 不得以任何方式或任何目的從事非法行為。
  3. 不得將您使用本服務之影片以任何方式揭露與任何第三人。
  4. 不得於使用本服務時諮詢與醫療衛生保健無關之問題。
  5. 不得於創設本服務帳號時偽造提供之個人資料。
  6. 不得影響或干擾本服務之提供，不得盜用他人使用本服務之帳號。
  7. 不得於使用本服務時辱罵、恐嚇或騷擾其他使用者或醫療人士。
  8. 如您違反前述禁止行為，蛹之生有權以任何方法減少因您違反禁止行為所造成之傷害， 包括但不限於刪除或禁止您的發言或回覆，或強制停用本服務。
10. 帳號停用
  1. 自行停用，請注意：一旦您自行選擇停用且刪除帳號(非更換移轉裝置)，您已付費之未完成預約將不被退還。
  2. 強制停用，您明確瞭解並同意蛹之生擁有並保留權利得在第九條內敘述之禁止行為情況發生時停用您的帳號。 （強制停用之情況如有更新，將於您登入時進行通知，請多加留意）
  3. 您在使用本系統時違反本條款或任何現行有效之法律。
11. 責任限制或免除
您應確保於使用本服務時您的網路連線正常， 如因您的網路連線問題造成您使用本服務所享有權利及服務完整度之減損，蛹之生並不為此負責。
您知悉並瞭解使用本服務而與醫療人士間成立之契約或其他法律關係，僅存在於您和醫療人士之間， 蛹之生並不為您與醫療人士間之任何問題負責。
您知悉並瞭解蛹之生無法完全控制及確保登錄於本系統之醫療人士身份之真實及合法性， 如有醫療人士以虛偽不實方式登錄於本系統者，蛹之生並不為此負責。
12. 損害賠償
使用者違反本條款任一約定致生損害於蛹之生者，應負損害賠償責任，蛹之生並有權終止您使用本服務。
13. 準據法與爭端解決
本條款適用中華民國法律。任何由本條款所引起或相關聯之爭議、糾紛、歧異， 或因此所致之違約、終止或無效事件，雙方同意由台灣台中地方法院為第一審管轄法院。
14. 條款效力可分割性
若本條款之任何規定有無效、違法、或無法執行之情況， 其他部分條款之有效性、合法性及可得執行性應保持完整不受影響。
  `,
    };
  },
};
</script>

<style lang="scss" scoped>
.rule-title {
  font-size: 20px;
  color: #295294;
}
</style>
