<template lang="pug">
.search-result.flex.column.p-4(@click="goLink")
  .inner-container.flex.p-20.align-center.gap-10
    .desc.flex.column
      .title {{ data?.title }}
</template>

<script>
export default {
  name: 'search-result',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {
    goLink() {
      if (this.data.link) {
        window.open(this.data.link, '_blank');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-result {
  min-width: 250px;
  flex: 1 0 30%;
  overflow: hidden;
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.04),
    0px 2px 6px 0px rgba(0, 0, 0, 0.06),
    0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  border-radius: 8px;
  background: #FFF;
  position: relative;
  cursor: pointer;

  .inner-container {
    // border: 2px solid #FFD68D;
    border-radius: 8px;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .title {
    overflow: hidden;
    max-width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
    color:  #414345;
  }
  .pro {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    color: #909399;
  }
  .image {
    border-radius: 10px;
    height: 68px;
    width: 68px;
    flex: 0 0 68px;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
}
</style>
