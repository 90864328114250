<template lang="pug">
.rule.flex.column.p-40.gap-20
  i.el-icon-arrow-left.clickable(@click="$router.back()") 返回
  .content.flex.column
    .rule-title {{ title }}
    .content.white-space-pre {{ content }}
</template>

<script>
export default {
  data() {
    return {
      title: '諮商前同意書',
      content: `
      最新更新日期：2022.08.24
最新版本生效日期：2022.08.24
1. 您同意本機構擬定之通訊心理諮商業務實施計畫書或通訊診療實施計畫書，以及進行實體暨遠距諮商之相關事項，包含：詢問病情、診察、開立處置醫囑、原有處方之調整或指導、衛生教育、心理諮商。
2. 您同意安裝機構合作之系統進行相關實體暨遠距諮商事項，配合本機構之相關規定進行使用，並授權所預約之醫療人士可觀看您的線上病歷紀錄或諮商紀錄以作為診察之參考。
3. 保密及例外：您在諮商中的談話與相關資料（包括心理測驗、諮商中的作品、諮商中的紀錄等），非經您的同意，醫療人士不得告知他人。但若您有傷害自己或他人的生命或安全而需通知相關人員等時、法律相關規定要求時、以及專業團隊討論、您為未成年個案而您的家屬要求查閱時及醫療人士接受督導則為保密之例外。
4. 關於諮商內容：
  (1). 專業人員：本機構服務皆由領有中華民國衛生福利部核發證照之心理師或醫療人士執行，或於醫師或在中央主管機關認可之醫院、機構於醫師、臨床心理師、諮商心理師指導下之全職實習或兼職實習心理師。
  (2). 服務地點：心理師將於所方諮商室內提供實體或遠距心理諮商服務，以維護您的諮商內容之保密性。遠距心理諮商服務不包含在全職實習或兼職實習心理師執行業務範疇。
  (3). 服務提供：服務對象需年滿18歲，青少年與兒童在治療有需要的情況下，得徵得法定代理人同意後進行；形式包含個人、伴侶、家庭。
  (4). 服務內容：包含情緒、感情、人際、家庭、職場⋯⋯等各種正向改變之需求。
  (5). 適合性評估：心理師或醫療人士需評估來談者適合諮商，包括：諮商的準備度、是否有急性精神疾病或自傷傷人疑慮、相關法規考量⋯⋯等。
5. 諮商時程：
  (1). 完整的諮商時程對成效非常重要，因此建議您至少完成諮商時程（6－8次），您可以與心理師或醫療人士共同商討後決定。
  (2). 安排的時程建議固定間隔，但以您與心理師或醫療人士協商決議為主。
  (3). 變更或中止：如欲變更諮商時程或中止諮商，請務必在前一次諮商中與心理師或醫療人士討論您的想法。
6. 收費標準：
  (1). 每次個別諮商以 50 分鐘為原則，收費為：（根據該心理師之諮商價格）元／50分鐘。
  (2). 伴侶／家庭諮商以 90－120 分鐘為原則，收費為：（根據心理師之諮商價格定價與該縣市衛生局規定）元／90－120 分鐘。
7. 請假或改期：如需更改或取消諮商預約，最晚請於諮商開始前 24 小時透過本機構之個管師，以協助您更改或取消該次預約。心理師如需更改或取消諮商預約亦同。若您因不可抗力之個人因素導致遲到或無法出席，最晚請於原訂諮商時間前 10 分鐘聯絡本機構之個管師，您知悉且同意當次諮商時長將可能因您之個人因素而縮減。若您未能聯絡個管師，您知悉並同意當次諮商將無法如期進行，且退費事宜將依本機構退費政策為準。
8. 您知悉機構之實體與遠距諮商並非全民健保所涵蓋之醫療補助範疇，同意自費負擔實體與遠距諮商時所產生之費用。
9. 您同意配合醫師法之通訊診療辦法以及心理師法之網路諮商實施原則相關守則，在與心理師或醫療人士進行遠距溝通時，妥善使用您自身之通訊設備。您亦同意，若有影音設備規格不足，或通訊狀態不佳等情況，對諮商造成明顯干擾時，您與心理師雙方均有權即時提出停止相關遠距心理諮商程序，並協議因應之方式。
10. 進行遠距心理諮商前，您應於視訊鏡頭前出示含照片之身分證件，供心理師核對身份。心理師亦應出示執業執照，供您核對心理師身份。
11. 您同意遵守實體諮商與遠距諮商之使用安全與道德規範，以及使用本服務的各項協議、規定、程序和慣例。您承諾不傳輸任何非法的、騷擾性的、中傷他人的、辱罵性的、恐嚇性的、傷害性的，或是淫穢之訊息資料。您也不能傳輸任何教唆他人構成犯罪行為的資料；不能傳輸助長國內不利條件和涉及國家安全的資料；不能傳輸任何不符合當地法規、國家法律和國際法律的影音或圖文資訊。您亦瞭解，未經許可非法進入其他電腦系統是違法行為。
12. 本服務內所標註之預約時間長度為此時段之總時間長度，並非實際諮商時間長度。諮商時間長度依該心理師或醫療人士諮商當中說明為標準。
13. 若同意參與遠距心理諮商，您同意每次執行遠距心理諮商時，應自行安排可個人獨處、安全及隔音良好之環境與心理師或醫療人士之溝通，心理師同時也保有以其專業判斷，若不符合規定，可即時停止遠距心理諮商之權益。
14. 若同意參與遠距心理諮商，您同意為了系統保護智慧財產權與您個人的醫療私密性，系統中所產生之會診內容禁止進行側錄，如有相關不聽從勸阻之情形，則本機構將尋求法律途徑處理。
15. 若同意參與遠距心理諮商，您知悉本機構所使用的遠距心理諮商平台為蛹之生心理諮商所開發的系統。
16. 若同意參與遠距心理諮商，代表您已年滿 18 歲為完全刑事責任能力之個案（根據：心理師執行通訊心理諮商業務核准作業參考原則規定）。
17. 您知悉本機構惟特殊目的之需求，包含但不限於實習生採集督導資訊、諮商過程必須，非取得您的簽名同意，則將不會以錄音（影）方式紀錄諮商過程。
18. 您同意遵守本份「諮商前同意書」上關於諮商費用、出席諮商規則、諮商關係避免、諮商結束、保密倫理、錄音（影）（如有）同意及資料保管、保護生命等相關內容。
19. 本醫療服務依循法規為：
  (1) 醫師法第 11 條增修訂辦法「通訊診察與治療辦法」。
  (2) 心理師法第 10 條增修訂辦法「心理師執行通訊心理諮商業務核准作業參考原則」。
  `,
    };
  },
};
</script>

<style lang="scss" scoped>
.rule-title {
  font-size: 20px;
  color: #295294;
}
</style>
