<template lang="pug">
.home.flex.column.p-20.gap-20
  .title.flex.align-center.gap-10
    .dots.flex.align-center.gap-4
      .dot.green
      .dot.yellow
      .dot.red
    .text 熱門文章
  .items.flex.align-center.wrap.justify-space-around.gap-20
    article-item(v-for="article in articles", :data="article", :key="article.url")
  .title.flex.align-center.gap-10
    .dots.flex.align-center.gap-4
      .dot.green
      .dot.yellow
      .dot.red
    .text 熱門課程
  .items.flex.align-center.wrap.justify-space-around.gap-20
    course-item(v-for="course in courses", :data="course", :key="course.url")
</template>

<script>
import {
  getActivities,
  getArticles,
  getCourses,
} from '@/api/app';
import Article from '../components/Article.vue';
import Course from '../components/Course.vue';

export default {
  components: {
    'article-item': Article,
    'course-item': Course,
  },
  data() {
    return {
      articles: [],
      courses: [],
    };
  },
  methods: {
    async loadData() {
      this.$execWithLoading(async () => {
        const datas = await Promise.all([
          getActivities(),
          this.loadArticles(),
          this.loadCourses(),
        ]);
        console.log(datas);
      });
    },
    async loadArticles() {
      const rsp = await getArticles();
      this.articles = rsp.slice(0, 6);
    },
    async loadCourses() {
      const rsp = await getCourses();
      this.courses = rsp.slice(0, 6);
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.home {
  .title {
    padding: 0 20px;
    .dot {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      &.green {
        background: #5AB0AC;
      }
      &.yellow {
        background: #FFD68D;
      }
      &.red {
        background: #E08E9F;
      }
    }
  }
  .items {
    padding: 0 20px;
  }
}
</style>
