<template lang="pug">
.rule.flex.column.p-40.gap-20
  i.el-icon-arrow-left.clickable(@click="$router.back()") 返回
  .content.flex.column
    .rule-title {{ title }}
    .content.white-space-pre {{ content }}
</template>

<script>
export default {
  data() {
    return {
      title: '預約、諮詢前收退款政策',
      content: `
最新更新日期：2023.12.03
最新版本生效日期：2023.12.03
蛹之生預約系統（下稱「本系統」）為蛹之生心理諮商所（下稱「蛹之生」）所創設並維護之健康諮詢及衛生保健媒合平台，為「app.morph.tw」網域之網站。 您同意並接受蛹之生提供之課程、遠距諮詢與實體諮詢（前三者合稱「本服務」）媒合服務，包含但不限於：衛生教育、情緒支持等，且知悉前文所述之服務皆非屬於醫療行為或健保給付之範疇，據此，您同意自費負擔使用本服務時所產生之相應費用。若您不同意本條款之部分或全部內容，您應立即停止使用本平台。 
請注意：在您完成諮商預約時，本系統即同時傳送您的病歷資料予該醫療人士、亦或是專業人士進行諮商前準備。因此，如您需變更或取消原預約諮商時間，您必須同意且接受【預約、諮詢前收退款政策】（下稱「本政策」）之以下規範：
1. 關於課程與諮詢內容
  1.1服務內容
  本服務為針對自我探索、關係、情緒困擾等議題提供課程、諮詢或轉介，建議執行次數將根據專業人士與您的需求客製化調整。
  (專業人士為蛹之生提供之諮詢服務皆由領有中華民國或海外主管機關核發證照之心理師或醫療人士執行；蛹之生提供之課程服務皆與專業團隊合作，並不定期針對系統進行訓練。)
  1.2服務地點
  為維護您使用本服務之保密性，以實體（包含但不限於合法立案之諮詢室、指定場域）或遠距形式提供，前揭形式皆將於獨立且不受干擾之空間進行。
  1.3服務時間
    (1)本服務內所標註之預約時間長度為該時段之總時間長度，並非實際課程、諮詢時間長度。實際時間長度依該專業人士於本服務當中說明為標準。
    (2)本平台暫時不支援自動化預約調整（包含取消或變更），任何預約、取消，亦或是變更等，包含，日期、時間、諮商空間等事宜，請透過個管師，以協助辦理變更預約事宜。如需「取消」諮商預約，最晚請於原訂諮商時間 24 小時以前聯絡個管師，以協助辦理取消諮商及退費事宜；原預約時間之諮詢開始後，專業人士將於當次服務時間出席至指定視訊空間，故不予更改預約或取消。
  1.4服務提醒
  您的預約可能為該預約時段中數個預約之一，系統將根據您的預約順序，顯示您的「預估諮商時間」（指開始之時間）。諮商時段可能應醫療人士之時間安排，不得順延您的諮商時段。您知悉且同意您的諮商時段可能因您未能及時進入視訊晤談，而有減少至不足 50 分鐘之可能，且若您未準時出席，當次服務時間將可能因您的遲到而縮減。
2. 系統及使用同意
  2.1個人資料使用同意
  您同意使用本服務時，授權您蛹之生與所預約之專業人士可查閱您使用本服務之過往紀錄作為該次服務之參考。
  2.2網路與環境措施
  您同意並配合網路諮詢守則，在與專業人士進行遠距溝通時，妥善使用您自身之通訊設備。您亦同意若因影音設備規格不足，或通訊狀態不佳等情況，於您使用本服務造成明顯干擾時，您與專業人士雙方均有權即時提出停止相關遠距程序，並協議因應之方式。若參與本服務之遠距形式（包含但不限於Google Meet、Jitsi Meet），您同意每次執行該服務時，應自行安排可獨處、安全及隔音良好之環境與專業人士溝通，專業人士同時也保有因其專業判斷有不符合相關規定之事項後，可即時停止本服務之權利。您亦知悉並同意若使用Google Meet之遠距形式，蛹之生無法控制這些網站及資源，因此您在使用前，需自行瞭解Google Meet 之使用者條款及隱私權政策
  2.3保密義務
  您在本服務中的談話與相關資料（包含但不限於因課程或諮詢所產生之心理測驗、作品以及活動紀錄等），非經您的同意，相關專業人士應負保密義務，不以包含但不限於口頭及書面或電子通訊之方式提供予無關之第三人。例外事項為您有傷害自己或他人的生命及安全之虞，蛹之生需通知相關人員之情況，或為法律相關規定要求以及經專業團隊討論之情況或您屬未成年個案而家屬要求查閱時，或專業人士接受督導之情況。前列皆屬保密義務之例外。
  2.4倫理規範
  您同意並遵守本服務之使用安全與道德規範，以及使用本服務的各項協議、規定、程序和慣例。您承諾不傳輸任何非法的、騷擾性的、中傷他人的、辱罵性的、恐嚇性的、傷害性的，或是淫穢之訊息資料等。您也不能傳輸任何教唆他人構成犯罪行為的資料；不能傳輸助長國內不利條件和涉及國家安全的資料；不能傳輸任何不符合當地法規、國家法律和國際法律的影音或圖文資訊。您亦瞭解，未經許可及非法進入其他電腦系統屬違法行為。
  2.5智慧財產權及隱私權
  若參與本服務，您同意為利系統保護智慧財產權與您個人的私密性，系統中所產生之課程或諮詢內容禁止進行側錄，如有相關不聽從勸阻之情形，則蛹之生將尋求法律途徑處理。
3. 收費及退費
  3.1預約收費機制
  蛹之生提供之本服務為自費制，為保障雙方權益，當雙方確定媒合完成諮商時段及醫療人士、專業人士等，請於接到本所通知後24小時內繳交諮商費用保證金，收到保證金後我們會幫您保留預約時段及晤談空間。保證金若無扣除之情事，將於晤談結束後轉為諮商費用，然若無進行晤談，且無下次預約之情事，則將保證金予以退還。
  3.2收費及憑證開立
  每次諮商結束後，如您的實施業務機構授權本平台自動開立諮商收據，則本平台將會自動寄出該收據至您的帳號電子信箱；如您無授權本平台自動開立諮商收據，則請您自行與個管師聯絡索取相關憑證。
  3.3變更政策之權利
  您明確知悉且同意，蛹之生保留在任何時間變更本政策之權利。如本政策有任何變更，蛹之生將於您預約課程或諮詢前，於系統公告中向您通知本政策之任何變更。
  3.4退費及憑證作廢
  如有退費情形，蛹之生將在 15 個工作天內根據您的支付方式完成退款作業（實際退款情形依您的發卡行決定），並寄送發票作廢證明至您註冊的電子信箱。如有退費疑義，請逕行聯絡個管師（LINE@：@morphtw）。
  請注意：電子發票為系統自動信件，若您的電子信箱之收件匣未有信件，再麻煩您至電子信箱之垃圾信件中查詢。
4. 變更政策之權利
您明確知悉且同意，蛹之生保留在任何時間變更本政策之權利。如本政策有任何變更，蛹之生將於您預約諮商前，於系統公告中向您通知本政策之任何變更。
5. 特殊情形規範
如有任何特殊情形，請聯絡個管師進行相關「申訴」，並說明您的情形。所有決議將依照您與機構達成共識為主。
  `,
    };
  },
};
</script>

<style lang="scss" scoped>
.rule-title {
  font-size: 20px;
  color: #295294;
}
</style>
