export function validateID(input) {
  let id = input.trim().toUpperCase();

  if (id.length !== 10) {
    return false;
  }

  const countyCode = id.charCodeAt(0);
  if (countyCode < 65 || countyCode > 90) {
    return false;
  }

  const genderCode = id.charCodeAt(1);
  if (genderCode !== 49 && genderCode !== 50) {
    return false;
  }

  const serialCode = id.slice(2);
  for (let i = 0; i < serialCode.length; i += 1) {
    const c = serialCode.charCodeAt(i);
    if (c < 48 || c > 57) {
      return false;
    }
  }

  const conver = 'ABCDEFGHJKLMNPQRSTUVXYWZIO';
  const weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];

  id = String(conver.indexOf(id[0]) + 10) + id.slice(1);

  let checkSum = 0;
  for (let i = 0; i < id.length; i += 1) {
    const c = parseInt(id[i], 10);
    const w = weights[i];
    checkSum += c * w;
  }

  const verification = (checkSum % 10 === 0);
  return verification;
}

export function validatePhone() {

}
