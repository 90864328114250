<template lang="pug">
.setting.p-20
  .content.flex.align-top.wrap.gap-20
    .single-column.flex.column.gap-12
      .title 個人設定
      .card.clickable.app-card-style.flex.align-center.p-20.gap-12(
        v-for="action in actions", :key="action.title"
        @click="doAction(action)")
        img.icon(:src="action.icon")
        .text.flex.column
          .title.font-size-14 {{ action.title }}
          .desc.text-secondary.font-size-12  {{ action.desc }}
      .card.clickable.app-card-style.flex.align-center.p-20.gap-12(
        @click="doAction(showLineCommand)")
        img.icon(:src="showLineCommand.icon")
        .text.flex.column
          .title.font-size-14 {{ showLineCommand.title }}
          .desc.text-secondary.font-size-12  {{ showLineCommand.desc }}
    .single-column.flex.column.gap-12
      .title 條款與政策
      .card.clickable.app-card-style.flex.align-center.p-20(@click="goRule(1)") 一般及醫療使用隱私權政策
      .card.clickable.app-card-style.flex.align-center.p-20(@click="goRule(2)") 帳號註冊同意書
      .card.clickable.app-card-style.flex.align-center.p-20(@click="goRule(3)") 諮商前同意書
      .card.clickable.app-card-style.flex.align-center.p-20(@click="goRule(4)") 預約、諮詢前收退款政策
  password-reset(ref="passwordReset")
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { unbindLINE } from '@/site/app/api/line';
import constant from '@/util/constant';
import DataImg from '../images/setting-data.svg';
import LineImg from '../images/setting-line.svg';
import PasswordImg from '../images/setting-password.svg';
import PasswordReset from '../components/drawer/PasswordReset.vue';

export default {
  components: {
    'password-reset': PasswordReset,
  },
  data() {
    return {
      actions: [
        {
          key: 'profile',
          icon: DataImg,
          title: '個人資料',
          desc: '為了建立專業的個人紀錄，需要您完成個人資料',
          action: this.goProfile,
        },
        {
          key: 'resetPassword',
          icon: PasswordImg,
          title: '重設密碼',
          desc: '',
          action: this.showReset,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['isLINELogin']),
    showLineCommand() {
      if (this.isLINELogin) {
        return {
          key: 'lineUnBinding',
          icon: LineImg,
          title: '解除綁定 蛹之生 LINE@',
          desc: '',
          action: this.unbindLINE,
        };
      }
      return {
        key: 'lineBinding',
        icon: LineImg,
        title: '綁定 蛹之生 LINE@',
        desc: '綁定後即可接收預約通知和新訊息',
        action: this.bindLINE,
      };
    },
  },
  methods: {
    ...mapMutations(['setIsLINELogin']),
    showReset() {
      this.$refs.passwordReset.$emit('show');
    },
    async unbindLINE() {
      return this.$execWithLoading(async () => {
        await unbindLINE();
        this.$showSuccess('綁定成功');
        this.setIsLINELogin(false);
      }, (e) => {
        this.$showAxiosException('解除綁定失敗', e);
      });
    },
    bindLINE() {
      const clinetID = constant.lineClientID;
      const redirectURL = encodeURIComponent(`${constant.appHost}`);
      const nonce = Math.floor(Math.random() * 1000);
      const state = 'bind';
      const url = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clinetID}&redirect_uri=${redirectURL}&scope=profile%20openid&nonce=${nonce}&state=${state}`;
      document.location.href = url;
    },

    goProfile() {
      this.$router.push('/profile');
    },
    doAction(action) {
      if (action.action) {
        action.action.apply(this);
      }
    },
    goRule(page) {
      this.$router.push(`/rule${page}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.single-column {
  width: 335px;
  flex: 0 0 auto;
}
</style>
