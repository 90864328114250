<template lang="pug">
el-drawer(
  title="重設密碼"
  :visible.sync="drawer"
  direction="rtl"
  :wrapperClosable="false"
  :before-close="handleClose"
  :append-to-body="appendToBody")
  .drawer.flex.column.gap-10
    .col.flex.column.gap-6
      .title 帳號
      el-input(:value="userEmail", disabled)
    .col.flex.column.gap-6
      .title 舊的密碼
      el-input(type="password" v-model="oldPassword" show-password)
    .col.flex.column.gap-6
      .title 新的密碼
      el-input(type="password" v-model="newPassword" show-password)
    .col.flex.column.gap-6
      .title 再次輸入新的密碼
      el-input(type="password" v-model="confirmPassword" show-password)
    .inputs.flex.gap-10
      app-button.flex-1 取消
      app-button.flex-1(type="primary", @click.native="save") 更新
</template>

<script>
import { mapGetters } from 'vuex';
import { appUpdatePassword } from '@/api/auth';
import AppButton from '../AppButton.vue';

export default {
  name: 'reset-password-drawer',
  components: {
    'app-button': AppButton,
  },
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    };
  },
  computed: {
    ...mapGetters(['userEmail']),
  },
  methods: {
    handleClose(done) {
      done();
    },
    close(result) {
      this.$emit('finish', result);
      this.drawer = false;
    },
    show() {
      this.drawer = true;
    },
    save() {
      if (this.oldPassword === '') {
        this.$showWarn('請輸入舊的密碼');
        return;
      }
      if (this.newPassword === '') {
        this.$showWarn('請輸入新的密碼');
        return;
      }
      if (this.newPassword !== this.confirmPassword) {
        this.$showWarn('再次輸入新的密碼與新的密碼不相符');
        return;
      }
      this.$execWithLoading(async () => {
        await appUpdatePassword(this.oldPassword, this.newPassword);
        this.$showSuccess('密碼更新成功');
        this.close(true);
      }, () => {
        this.$showError('密碼更新失敗，請稍後重試');
      });
    },
  },
  mounted() {
    this.$on('show', () => {
      this.oldPassword = '';
      this.newPassword = '';
      this.confirmPassword = '';
      this.show();
    });
  },
};
</script>

<style lang="scss" scoped>
.drawer {
  padding: 20px;
  padding-top: 0;
}
</style>
