<template lang="pug">
.login-page.flex.column.align-center
  .header.flex.align-center
    .logo
  .content.flex.column.align-center.justify-center
    img.form-bg(:src="bgImg")
    .form.flex.column.align-stretch.gap-20
      .desc.flex.column
        div 歡迎來到蛹之生心理諮商所
        div Welcome to Metamorphosis Counseling Center
      .desc.flex.column
        div 用心理諮商陪你走生活的苦、解生命的結
        div
          span We use psychological counseling to accompany you&nbsp;
          span through the hardships of life and untie the knots of life.
      el-button
        .btn-content.flex.align-cneter.justify-center.gap-10(@click="goLINELogin")
          img(src="/line.png", width="18" height="18")
          .btn-text 使用 LINE 登入 Login via LINE
      .splitter.flex.align-center.gap-10
        .line.flex-1
        .text 或是 / or
        .line.flex-1
      .inputs.flex.column.gap-10
        .input.flex.column
          .title Email
          el-input(v-model="email")
        .input.flex.column
          .title 密碼 Password
          el-input(v-model="password", type="password")
        el-button(type="primary", @click="doLogin") 登入 Login
        .forget-pass
          el-link(type="primary", @click="doForget") 忘記密碼? Forgot password?
      .contact-info.flex.column
        .row 客服LINE帳號： @morphtw
        .row 客服信箱：reservation@morph.tw
        .row 客服電話：0908-620-620
        .row 服務時間：週一至週六 09:00-21:00，國定假日除外
</template>

<script>
import { mapMutations } from 'vuex';
import constant from '@/util/constant';
import { login, getProfile } from '@/api/auth';
import bgImg from '../images/login-bg-img.svg';

export default {
  data() {
    return {
      bgImg,
      email: '',
      password: '',
    };
  },
  methods: {
    ...mapMutations(['setUserInfo', 'setIsLINELogin']),
    doLogin() {
      this.$execWithLoading(async () => {
        await login(this.email, this.password);
        this.email = '';
        this.password = '';
        const profile = await getProfile();
        this.setUserInfo(profile);
        this.$router.push({ name: 'Reserves' });
      }, () => {
        this.$showError('登入失敗');
      });
    },
    doForget() {
      this.$router.push('/forget-password');
    },
    goLINELogin() {
      const clinetID = constant.lineClientID;
      const redirectURL = encodeURIComponent(`${constant.appHost}`);
      const nonce = Math.floor(Math.random() * 1000);
      const state = 'login';
      const url = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clinetID}&redirect_uri=${redirectURL}&scope=profile%20openid&nonce=${nonce}&state=${state}`;
      document.location.href = url;
    },
  },
  mounted() {
    this.setUserInfo(undefined);
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  position: relative;
  .header {
    position: absolute;
    margin-top: 50px;
    width: 100vw;
    .logo {
      margin-left: 40px;
      box-sizing: border-box;
      flex: 0 0 285px;
      background-image: url('/black-logo-long.png');
      background-position: 50% 50%;
      background-size: 285px 45px;
      background-repeat: no-repeat;
      border-right: 1px solid white;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
    }
  }
  .content {
    padding-top: 45px;
    flex: 1;
    max-width: 820px;
    width: 100vw;
    position: relative;
    .form-bg {
      position: absolute;
      top: calc(50% + 350px);
      right: 160px;
      transform: translate(100%, -100%);
      width: 22%;
      min-width: 160px;
    }
    .form {
      width: calc(100% - 70px);
      max-width: 386px;
      margin: 35px;

      .btn-text {
        margin-top: 3px;
        font-size: 13px;
      }
      .splitter {
        color: #909399;
        .line {
          border-bottom: 1px dashed #909399;
        }
      }
    }
  }
  .contact-info {
    font-size: 12px;
  }
}

@media screen and (max-width: 800px) {
  .login-page {
    .header {
      margin-top: 30px;
      .logo {
        margin-left: 35px;
        flex: 0 0 200px;
        background-size: 200px 32px;
      }
    }
  }
}
</style>
