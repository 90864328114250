<template lang="pug">
el-drawer(
  title="預約詳細資訊"
  :visible.sync="drawer"
  direction="rtl"
  :before-close="handleClose"
  :append-to-body="appendToBody")
  .drawer.flex.column.gap-10
    template(v-if="state === 0")
      .info-card.app-card-style.flex.column.overflow-hidden
        .professional.p-20.flex.align-center.gap-20
          .avatar
            img(:src="showData.professional?.avatar")
          .flex.column
            .name.font-size-18.bold {{ showData.proName }}
            .title.font-size-12.text-secondary {{ showData.professional?.title || '專業人士' }}
        .pos.flex.align-center.gap-30.p-20.align-start
          .branch.font-size-20.bold.flex-1.mt-8 {{ showData?.location?.branch?.name }}
          .info-sec.flex.column.gap-20
            .time-sec.flex-column
              .date.text-secondary.font-size-12 {{ $timestampToDateWithDay(showData.start_at ) }}
              .time.flex.align-center
                .start.flex.align-start.gap-4
                  .font-size-20.bold {{ $timestampToTime(showData.start_at) }}
                  .font-size-12 {{ $timestampToM(showData.start_at) }}
                .to ~
                .end.flex.align-start.gap-4
                  .font-size-20.bold {{ $timestampToTime(showData.end_at) }}
                  .font-size-12 {{ $timestampToM(showData.end_at) }}
            .partner-sec.flex-column(v-if="showData.showPartners")
              .date.text-secondary.font-size-12
                template(v-if="showData.appointment_type === 'couple'") 伴侶諮商
                template(v-else-if="showData.appointment_type === 'parent'") 親子諮商
                template(v-else-if="showData.appointment_type === 'family'") 家庭諮商
                template(v-else-if="showData.appointment_type === 'earlytreatment'") 早療團體諮商
              .partner.flex.align-center
                .font-size-20.bold(v-if="showData.appointment_type === 'earlytreatment'") {{ userDisplayName }}
                .font-size-20.bold(v-else) {{ showData.user.family_name }}{{ showData.user.given_name }}
                template(v-if="showData.appointment_type !== 'earlytreatment'")
                  .font-size-20.bold(v-for="(p, idx) in (showData?.partners || [])")
                    template 、{{ p.family_name }}{{ p.given_name }}
                template(v-else)
                  .font-size-20.bold &nbsp;與其他{{showData?.participaint_count}}人
        template(v-if="showData.location_type === 'virtual'")
          template(v-if="showData.payment === 'notpaid'")
            .pay-info.flex.align-center.justify-center.bold(
              :class="{'need-pay': now < showData.start_at, 'not-paid': now > showData.start_at}"
            )
              span(v-if="now < showData.start_at") 晤談開始前，先進行付款
              span(v-else-if="now >= showData.start_at") 尚未完成付款,無法開啟連結
              span(v-else-if="now > showData.end_at") 晤談時間已結束
          template(v-else)
            .pay-info.flex.align-center.justify-center.bold.clickable(
              @click="goRemote"
              :class="{'need-pay': !canOpenLink}"
            )
              span(v-if="now < showData.start_at - remoteLinkTimeOffset") 尚未開放進入
              span(v-else-if="now > showData.end_at + remoteLinkTimeOffset") 晤談時間已結束
              span(v-else) 開始遠距晤談
      .payment-card.app-card-style.flex.align-stretch.overflow-hidden
        .price.flex.column.justify-center.flex-1.p-20.gap-4
          template(v-if="showData.project")
            .desc.font-size-12.text-secondary 本次費用為
            .line.flex.align-center.gap-4
              .price-num.bold {{ showData.organization_name }}
              span 補助
          template(v-else)
            .desc.font-size-12.text-secondary 本次費用為自費
            .price-num.font-size-20.bold NT $ {{ showData.price }}
        .button.flex.align-center.p-20.gap-8(
          v-if="btnStatus !== ''"
          :class="[btnStatus, btnClickable ? 'clickable' : '']")
          template(v-if="btnStatus === 'inhouse_normal'")
            i.el-icon-info
          template(v-else-if="btnStatus === 'to_pay'")
            i.el-icon-warning
          template(v-else-if="btnStatus === 'not_pay_end'")
            i.el-icon-warning
          template(v-else-if="btnStatus === 'finish_pay'")
            i.el-icon-success
          template(v-else-if="btnStatus === 'download_receipt'")
            i.el-icon-download
          template(v-else-if="btnStatus === 'no_pay'")
            i.el-icon-success
          template(v-else-if="btnStatus === 'download_approve'")
            i.el-icon-download
          .desc.font-size-12.clickable(@click="handle(btnStatus)")
            template(v-if="btnStatus === 'inhouse_normal'") 晤談結束後，請至櫃檯付款
            template(v-else-if="btnStatus === 'to_pay'") 進行付款
            template(v-else-if="btnStatus === 'not_pay_end'") 尚未付款
            template(v-else-if="btnStatus === 'refresh'") 重新整理支付狀態
            template(v-else-if="btnStatus === 'finish_pay'") 已完成付款
            template(v-else-if="btnStatus === 'download_receipt'") 下載收據
            template(v-else-if="btnStatus === 'no_pay'") 無須付款
            template(v-else-if="btnStatus === 'download_approve'") 出席證明
      .desc.text-secondary.flex.column.gap-4
      .line 提醒：
      .line.flex.align-start.gap-4
        span 1.
        span 請您於約定好時間準時到來，因心理師已預留時間，也無法安排其他來談者，若有臨時更動須提早告知更改；若您於預約時間晚到而影響晤談時間，恕無法提供您補時間或退費安排。
      .line.flex.align-start.gap-4
        span 2.
        span 採自費晤談者，結束後將能下載收據。
    template(v-if="state === 1")
      .place-form.app-card-style.flex.column.overflow-hidden.p-20.gap-10
        .desc 依照醫療相關法規及主管機關規定，通訊諮商需先完成個人資料及紀錄每次當事人晤談所在位置，完成後始能開始晤談。
        .success-color.flex.gap-10.align-center
          i.el-icon-success
          span 個人資料已完成
        template(v-if="submitPlace === ''")
          .warn-color.flex.gap-10.align-center
            i.el-icon-warning
            span 本次晤談您的位置在哪裡？
          .input.pl-24
            el-input(placeholder="請輸入地址或地標", v-model="place")
          el-button.app-primary(:disabled="place === ''", @click="savePlace") 送出
        template(v-else)
          .success-color.flex.gap-10.align-center
            i.el-icon-success
            span 本次晤談的位置：{{ submitPlace }}
          el-button.app-primary(@click="openRemote") 開啟晤談連結
</template>

<script>
import { mapGetters } from 'vuex';
import { getAppointment, createAppointmentTx, updateAppointmentPlace } from '@/api/reserve';
// import { openWithPost } from '@/util/misc';

const remoteLinkTimeOffset = 15 * 60; // 15 mins

const btnStatus = [
  '',
  'inhouse_normal',
  'to_pay',
  'finish_pay',
  'download_receipt',
  'no_pay',
  'download_approve',
  'refresh',
  'not_pay_end',
];

function isWebview() {
  const useragent = navigator.userAgent;
  const rules = ['WebView', '(iPhone|iPod|iPad)(?!.*Safari/)', 'Android.*(wv|.0.0.0)'];
  const regex = new RegExp(`(${rules.join('|')})`, 'ig');
  return Boolean(useragent.match(regex));
}

export default {
  name: 'reserve-view',
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      showData: {},
      btnStatus: 'to_pay',
      now: 0,
      state: 0,
      place: '',
      submitPlace: '',
      remoteLinkTimeOffset,
    };
  },
  computed: {
    ...mapGetters(['userDisplayName']),
    btnClickable() {
      return ['to_pay', 'download_receipt', 'download_approve'].indexOf(this.btnStatus) >= 0;
    },
    canOpenLink() {
      return this.now >= this.showData.start_at - remoteLinkTimeOffset && this.now <= this.showData.end_at + remoteLinkTimeOffset;
    },
  },
  methods: {
    goRemote() {
      if (!this.canOpenLink) {
        return;
      }
      this.state = 1;
    },
    openRemote() {
      if (this.showData.meeting_link) {
        this.$goLink(this.showData.meeting_link);
      }
    },
    handle(status) {
      if (status === 'to_pay') {
        this.$execWithLoading(async () => {
          const rsp = await createAppointmentTx(this.showData.id);
          console.log(rsp);
          const url = rsp.payment_url;
          const data = {
            MerchantID: rsp.merchant_id,
            TradeInfo: rsp.trade_info,
            TradeSha: rsp.trade_sha,
            Version: '2.0',
          };

          this.setBtnStatus('refresh');

          const redirectURL = `/payment.html?MerchantID=${data.MerchantID}&TradeInfo=${data.TradeInfo}&TradeSha=${data.TradeSha}&target=${encodeURIComponent(url)}&openExternalBrowser=1`;
          if (isWebview()) {
            window.location.href = redirectURL;
          } else {
            setTimeout(() => {
              window.open(redirectURL, '_blank');
              console.log(this.$refs.form);
            }, 0);
          }
        }, (e) => {
          console.log(e);
        });
      } else if (status === 'refresh') {
        this.loadPaidStatus();
      } else if (status === 'download_receipt') {
        if (this.showData.receipt) {
          this.$goLink(this.showData.receipt);
        } else {
          this.$showWarn('無法下載收據', '收據尚未產生，請稍後再試');
        }
      }
    },
    setBtnStatus(status) {
      if (btnStatus.indexOf(status) >= 0) {
        this.btnStatus = status;
      }
    },
    handleClose(done) {
      if (this.state === 0) {
        done();
      } else if (this.state === 1) {
        this.state = 0;
      }
    },
    close(result) {
      this.$emit('finish', result);
      this.drawer = false;
    },
    show() {
      this.drawer = true;
      this.syncNow();
    },
    syncNow() {
      this.now = parseInt(new Date().getTime() / 1000, 10);
      setTimeout(() => {
        if (this.drawer) {
          this.syncNow();
        }
      }, 500);
    },
    async loadData() {
      const data = await getAppointment(this.showData.id);
      const showPartners = ((data?.partners || []).length > 0 || data.participaint_count > 0) && (
        data.appointment_type === 'couple'
          || data.appointment_type === 'family'
          || data.appointment_type === 'earlytreatment'
      );
      let { price } = data;
      if (data.appointment_type === 'earlytreatment') {
        price = parseInt(price / (data.participaint_count + 1), 10);
      }
      this.showData = {
        ...data,
        price,
        showPartners,
        proName: `${data.professional?.family_name}${data.professional?.given_name}`,
      };

      const now = parseInt(new Date().getTime() / 1000, 10);
      const hasStart = data.start_at < now;
      const finish = data.end_at < now;
      const isRemote = (data.location_type !== 'inhouse');
      const isPaid = (data.payment !== 'notpaid');
      const hasProject = (data.project !== undefined && data.project !== '');
      const showReceipt = (
        (data.appointment_type === 'earlytreatment' && (data.partner || []).legnth > 0)
        || hasProject
      );

      if (data.project) {
        this.setBtnStatus('no_pay');
        return;
      }

      if (!hasStart || !finish) {
        if (!isRemote) {
          if (isPaid) {
            this.setBtnStatus('finish_pay');
          } else {
            this.setBtnStatus('inhouse_normal');
          }
        } else if (isPaid) {
          this.setBtnStatus('finish_pay');
        } else {
          this.setBtnStatus('to_pay');
        }
      }
      if (finish) {
        if (!isRemote) {
          if (isPaid) {
            if (showReceipt) {
              this.setBtnStatus('download_receipt');
            }
          } else {
            this.setBtnStatus('inhouse_normal');
          }
        } else if (isPaid) {
          if (showReceipt) {
            this.setBtnStatus('download_receipt');
          }
        } else {
          this.setBtnStatus('not_pay_end');
        }
      }
    },
    loadPaidStatus() {
      return this.$execWithLoading(async () => {
        await this.loadData();
        this.$emit('update', this.showData);
      });
    },
    loadAndShow() {
      return this.$execWithLoading(async () => {
        await this.loadData();
        this.show();
      });
    },
    savePlace() {
      this.$execWithLoading(async () => {
        await updateAppointmentPlace(this.showData.id, this.place);
        this.submitPlace = this.place;
      }, (e) => {
        this.$showAxiosException('更新地點失敗', e);
      });
    },
  },
  mounted() {
    this.$on('show', (data) => {
      this.showData = data;
      this.place = '';
      this.submitPlace = '';
      this.state = 0;
      this.loadAndShow();
    });
  },
};
</script>

<style lang="scss" scoped>
.drawer {
  padding: 20px;
  padding-top: 0;

  .info-card {
    .professional {
      flex: 0 0 95px;
      .avatar {
        flex: 0 0 50px;
        border-radius: 25px;
        overflow: hidden;
        img {
          height: 50px;
          width: 50px;
        }
      }
    }
    .pos {
      flex: 1 0 88px;
      background: #F2FAFA;
      color: #5AB0AC;
    }

    .pay-info {
      flex: 0 0 60px;
      background: rgba(90, 176, 172, 0.96);
      color: white;
      &.need-pay {
        background: #B1B3B8;
        color: white;
      }
      &.not-paid {
        background: #FEF0F0;
        color: #F56C6C;
      }
    }
  }
  .payment-card {
    flex: 0 0 88px;
    .button {
      flex: 0 0 146px;
      background: #F4F4F5;
      color: #909399;
      &.to_pay, &.download-receipt, &.download_approve {
        background: #ECF5FF;
        color: #409EFF;
      }
      &.finish_pay, &.no_pay {
        color: #67C23A;
        background: #F0F9EB;
      }
    }
  }
}
.app-primary {
  background: #5AB0ACF5;
  color: white;
  border: none;
  &:hover {
    background: #5AB0AC;
  }
  &:active {
    background: #5AB0ACF5;
  }
  &:focus {
    background: #5AB0AC;
  }
}
</style>
