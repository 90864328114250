/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

function isEmpty(value) {
  return value === '' || value === undefined || value === null || value === 'None';
}

export default new Vuex.Store({
  state: {
    user: undefined,
    isLINELogin: false,
  },
  mutations: {
    setUserInfo(s, user) {
      s.user = user;
    },
    setIsLINELogin(s, val) {
      s.isLINELogin = val;
      localStorage.setItem('is-line-login', JSON.stringify(val));
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
    userEmail: (s) => s.user?.email || '',
    userDisplayName: (s) => {
      if (s.user?.family_name && s.user?.given_name) {
        return `${s.user.family_name}${s.user.given_name}`;
      }
      return '使用者姓名';
    },
    isLogin: (s) => s.user !== undefined,
    profileNeedUpdate: (s) => {
      const info = s.user;
      if (info === undefined) {
        return false;
      }

      if (isEmpty(info.phone)) {
        return true;
      }
      if (isEmpty(info.sex)) {
        return true;
      }
      if (isEmpty(info.emergency_person)) {
        return true;
      }
      if (isEmpty(info.emergency_relationship)) {
        return true;
      }
      if (isEmpty(info.emergency_contact)) {
        return true;
      }
      if (info.country === 'TW') {
        return isEmpty(info.county) || isEmpty(info.district);
      }

      return false;
    },
    isLINELogin(s) {
      const val = JSON.parse(localStorage.getItem('is-line-login'));
      if (val) {
        s.isLINELogin = val;
      }
      return s.isLINELogin;
    },
  },
});
