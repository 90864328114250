import Vue from 'vue';
import ElementUI from 'element-ui';
import VueRouter from 'vue-router';

import VueQRCodeComponent from 'vue-qr-generator';
import locale from 'element-ui/lib/locale/lang/zh-TW';
import PlaceSelector from '@/components/PlaceSelector.vue';
import ProfessionalPickerVue from '@/components/ProfessionalPicker.vue';
import ProfessionalSelectorVue from '@/components/ProfessionalSelector.vue';
import ProBadge from '@/components/ProfessionalBadge.vue';
import UserPicker from '@/components/UserPicker.vue';
import Toggle from '@/components/input/Toggle.vue';
import misc from '@/plugins/misc';
import inputErr from '@/plugins/inputErr';
import msg from '@/plugins/enum';

import EducationSelect from '@/components/input/EducationSelect.vue';
import RelationSelect from '@/components/input/RelationSelect.vue';

import App from './App.vue';
import routes from './router';
import store from './store';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/style/global_app.scss';

Vue.use(ElementUI, { locale });

Vue.component('qr-code', VueQRCodeComponent);

Vue.config.productionTip = false;
Vue.use(misc);
Vue.use(inputErr);
Vue.use(msg);
Vue.use(VueRouter);
Vue.component('place-selector', PlaceSelector);
Vue.component('pro-picker', ProfessionalPickerVue);
Vue.component('pro-selector', ProfessionalSelectorVue);
Vue.component('user-picker', UserPicker);
Vue.component('toggle', Toggle);
Vue.component('pro-badge', ProBadge);
Vue.component('education-select', EducationSelect);
Vue.component('relation-select', RelationSelect);

// window.mockMode = true;

const router = new VueRouter({
  routes,
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
