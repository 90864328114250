<template lang="pug">
#app.safari-wrap.flex.column
  template(v-if="ready")
    .header.flex(:class="{'show': isLogin}")
      el-tooltip(v-if="version", placement="bottom", :content="version")
        .logo(@click="$copy(version, true)")
      .logo(v-else)
      .menu.flex.align-center.gap-16
        .menu-item.flex.align-center.justify-center.gap-10.clickable(
          v-for="shortcut in shortcuts"
          @click="goPage(shortcut)"
          :class="{'active': menu === shortcut.key}")
          i(:class="`el-icon-${shortcut.icon}`")
          .text {{  shortcut.text  }}
      .blank.rwd-only.flex-1
      .logout.flex.align-center.clickable(@click="logout") 登出
    router-view.content
  .shortcuts.flex.align-center.justify-space-around.rwd-only(
    v-if="isLogin && !profileNeedUpdate")
    .shortcut.flex.column.align-center(
      :class="{'active': menu === shortcut.key}"
      @click="goPage(shortcut)"
      v-for="shortcut in shortcuts", :key="shortcut.key")
      .icon.flex.align-center.justify-center
        i(:class="`el-icon-${shortcut.icon}`")
      .name {{ shortcut.text }}
  .update-data-first-alert(v-if="dataAlertVisible")
    .mask
    .dialog.flex.column
      .banner.flex.column.align-center.justify-center.gap-16
        img(:src="bannerImage")
        div 請先完成您的基本資料，方能繼續使用蛹之生的諮商/諮詢服務
        div
          span Please complete your basic information first to continue using&nbsp;
          span Metamorphosis Counseling Center's counseling/consultation services.
      .op.flex.column.align-center.justify-center
        el-button.app-primary(@click="dataAlertVisible = false")
          .btn-line 了解，開始填寫
          .btn-line Okay, let me fill it out right away
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { getProfile, logout } from '@/api/auth';
import bannerImage from './images/banner-logo.svg';
import router from './router';

export default {
  data() {
    return {
      bannerImage,
      menu: 'Home',
      ready: false,
      detailUser: undefined,

      shortcuts: [
        { icon: 'house', key: 'Home', text: '首頁' },
        { icon: 'date', key: 'Reserves', text: '預約' },
        { icon: 'orange', key: 'Explore', text: '探索' },
        { icon: 'chat-line-square', key: 'Message', text: '訊息' },
        { icon: 'user', key: 'Setting', text: '設定' },
      ],
      version: undefined,
      dataAlertVisible: false,
    };
  },
  computed: {
    ...mapGetters(['userDisplayName', 'isLogin', 'profileNeedUpdate']),
  },
  watch: {
    $route() {
      this.detailUser = undefined;
      this.checkRoute();
    },
    profileNeedUpdate() {
      if (this.$route.path === '/login' || this.$route.pash === '/profile') {
        return;
      }
      if (this.profileNeedUpdate) {
        this.showDataAlert();
      }
    },
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    goPage(shortcut) {
      if (shortcut.key === 'Message') {
        this.$goLink('https://lin.ee/u84WVJ2');
        return;
      }
      this.$router.push({ name: shortcut.key });
    },
    logout() {
      this.setUserInfo(undefined);
      this.$router.push('/login');
      logout();
    },
    async loadData() {
      return this.$execWithLoading(async () => {
        const profile = await getProfile();
        this.setUserInfo(profile);
      });
    },
    changePage(key) {
      if (key === 'Logout') {
        this.logout();
        return;
      }
      this.$router.push(`/${key.toLowerCase()}`).catch(this.handleDuplicatedRoute);
    },
    checkRoute() {
      router.forEach((r) => {
        let find = false;
        if (r.name === this.$route.name) {
          find = true;
        }
        if (!find && r.children !== undefined) {
          r.children.forEach((child) => {
            if (child.name === this.$route.name) {
              find = true;
            }
          });
        }
        if (find) {
          this.menu = r.name;
          if (r.needAuth && !this.isLogin) {
            this.$router.push('/login');
          }
        }
      });
    },
    handleDuplicatedRoute(error) {
      if (error.name !== 'NavigationDuplicated') {
        throw error;
      }
      this.ready = false;
      setTimeout(() => {
        this.ready = true;
        this.$forceUpdate();
      }, 100);
    },
    showDataAlert() {
      this.dataAlertVisible = true;
      this.$router.push('/profile');
    },
  },
  async mounted() {
    function safariHacks() {
      const windowsVH = window.innerHeight / 100;
      document.querySelector('.safari-wrap').style.setProperty('--vh', `${windowsVH}px`);
      window.addEventListener('resize', () => {
        document.querySelector('.safari-wrap').style.setProperty('--vh', `${windowsVH}px`);
      });
    }

    window.addEventListener('resize', () => {
      safariHacks();
    });

    this.version = await this.$getVersion();

    await this.loadData();
    this.checkRoute();
    this.ready = true;
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-regular-color;
  font-size: 14px;
  .content {
    flex: 1;
    overflow-x: hidden;
    background-color: #F9F5F1;
  }
}
.header {
  max-height: 0;
  transition: transform .3s ease-in-out;
  border-bottom: solid 1px #e6e6e6;
  overflow: hidden;

  &.show {
    max-height: $header-height;
  }
  .logo {
    box-sizing: border-box;
    flex: 0 0 240px;
    background-image: url('/color-logo-long.png');
    background-position: 50% 50%;
    background-size: 200px 32px;
    background-repeat: no-repeat;
    border-right: 1px solid white;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
  .menu {
    flex: 1;
    border: none;
    height: 56px;
    .menu-item {
      flex: 0 0 67px;
      height: 36px;
      font-size: 12px;
      &.active {
        background: #E7F2F2;
        border-radius: 10px;
        color: rgba(90, 176, 172, 0.96);
      }
    }
  }
  .user {
    padding: 0 20px;
    color: white;
  }
  .logout {
    padding: 0 20px;
  }
  .menu-icon {
    padding: 0 20px;
    color: white;
  }
}

.shortcuts {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 80px;
  background: #F9FCFC;
  .active {
    color: rgba(90, 176, 172, 0.96);
    .icon {
      border-radius: 30px;
      background: rgba(161, 201, 200, 0.2);
    }
  }
  .icon {
    flex: 39px;
    width: 39px;
    i {
      width: 28px;
      max-width: 28px;
      height: 28px;
      flex: 28px;
      font-size: 28px;
    }
  }
}
.update-data-first-alert {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    height: 100dvh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.25);
  }
  .dialog {
    height: 414px;
    flex: 0 0 100vw;
    max-width: calc(100vw - 40px);
    background: white;
    z-index: 1;
    border-radius: 10px;
    overflow: hidden;
    .banner {
      flex: 0 0 267px;
      background: #E7F2F2;
    }
    .op {
      flex: 1;
    }
    .app-primary {
      background: #5AB0ACF5;
      color: white;
      border: none;
      &:hover {
        background: #5AB0AC;
      }
      &:active {
        background: #5AB0ACF5;
      }
      &:focus {
        background: #5AB0AC;
      }
      .btn-line {
        line-height: 18px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  #app {
    .header {
      .menu {
        display: none;
      }
    }
    .content {
      padding-bottom: 100px !important;
    }
  }
  .dropdown-name {
    width: 250px;
  }
}
</style>
