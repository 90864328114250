<template lang="pug">
.password-page.flex.column.align-center
  .header.flex.align-center
    .logo
  .content.flex.column.align-center.justify-center
    img.form-bg(:src="bgImg")
    .form.flex.column.align-stretch.gap-20(v-if="state === 1")
      .desc.flex.column
        div 您的信箱已完成驗證，後續將用作您的登入帳號，接下來您可以繼續完成密碼設定
        div
          span Your email has been verified and will be used as your login account.
          span You can now proceed to set your password.
      .inputs.flex.column.gap-10
        .input.flex.column
          .title 電子郵件 E-mail
          el-input(v-model="email" disabled)
        .input.flex.column
          .title 密碼 Password
          el-input(v-model="password", type="password")
        .input.flex.column
          .title 確認密碼 Password confirmation
          el-input(v-model="confirmPassword", type="password")
      .desc.flex.column
        div
          span 你若按送出完成帳號設定，代表你瞭解並同意&nbsp;
          span 「註冊帳號同意書」與「隱私權政策」之所有內容，並完全接受本服務現有未來衍生的服務項目及內容。
        div
          span If you click "Submit" to complete your account settings, you agree&nbsp;
          span to all the terms and conditions of the "Account Registration Agreement"&nbsp;
          span and the "Privacy Policy", and fully accept the existing and&nbsp;
          span future services and content of this service.
      el-button(type="primary", @click="doReset") 送出 Submit
    .form.flex.column.align-stretch.gap-20(v-if="state === 2")
      .desc.flex.column
        div 成功設定完成！
        div Setup successfully completed !
      el-button(type="primary", @click="goLogin") 開啟登入頁面 Open the login page
</template>

<script>
import { mapMutations } from 'vuex';
import { appUpdatePasswordByToken } from '@/api/auth';
import bgImg from '../images/login-bg-img.svg';

export default {
  name: 'email-validation',
  data() {
    return {
      state: 1,
      bgImg,
      email: '',
      password: '',
      confirmPassword: '',
      token: '',
    };
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    doReset() {
      this.$execWithLoading(async () => {
        await appUpdatePasswordByToken(this.token, this.password);
        this.state = 2;
      }, (e) => {
        console.log(e);
        this.$showError('更新密碼失敗');
      });
    },
    goLogin() {
      this.$router.push({ name: 'Login' });
    },
  },
  mounted() {
    this.setUserInfo(undefined);
    const queryString = window.location.hash.replace('#/email-validation', '');
    const urlParams = new URLSearchParams(queryString);
    console.log(urlParams);
    this.token = urlParams.get('token');
    this.email = urlParams.get('email');
  },
};
</script>

  <style lang="scss" scoped>
  .password-page {
    position: relative;
    .header {
      position: absolute;
      margin-top: 50px;
      width: 100vw;
      .logo {
        margin-left: 40px;
        box-sizing: border-box;
        flex: 0 0 285px;
        background-image: url('/black-logo-long.png');
        background-position: 50% 50%;
        background-size: 285px 45px;
        background-repeat: no-repeat;
        border-right: 1px solid white;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
      }
    }
    .content {
      flex: 1;
      max-width: 820px;
      width: 100vw;
      padding-top: 50px;
      position: relative;
      .form-bg {
        position: absolute;
        top: calc(50% + 350px);
        right: 160px;
        transform: translate(100%, -100%);
        width: 22%;
        min-width: 160px;
      }
      .form {
        width: calc(100% - 70px);
        max-width: 386px;
        margin: 35px;

        .btn-text {
          margin-top: 3px;
          font-size: 13px;
        }
        .splitter {
          color: #909399;
          .line {
            border-bottom: 1px dashed #909399;
          }
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    .password-page {
      .header {
        margin-top: 30px;
        .logo {
          margin-left: 35px;
          flex: 0 0 200px;
          background-size: 200px 32px;
        }
      }
    }
  }
  </style>
