<template lang="pug">
.article-item.flex.column.p-20(@click="goLink")
  .bar
  .title {{ data?.title }}
  .pro {{ data.author }} {{ data.author_title }}
  .image(:style="imageStyle")
</template>

<script>
export default {
  name: 'article-item',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    imageStyle() {
      if (this.data.image_url) {
        return {
          'background-image': `url(${this.data.image_url})`,
        };
      }
      return {};
    },
  },
  methods: {
    goLink() {
      if (this.data.url) {
        window.open(this.data.url, '_blank');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.article-item {
  min-width: 250px;
  flex: 1 0 30%;
  overflow: hidden;
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.04),
    0px 2px 6px 0px rgba(0, 0, 0, 0.06),
    0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  border-radius: 8px;
  border: 2px solid #E08E9F;
  background: #FFF;
  position: relative;
  cursor: pointer;

  .bar {
    position: absolute;
    width: 120px;
    height: 10px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #E08E9F;
    border-radius: 0px 0px 84px 84px;
  }

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
  }
  .pro {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    color: #909399;
  }
  .image {
    margin-top: 20px;
    border-radius: 10px;
    height: 120px;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
}
</style>
