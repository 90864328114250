<template lang="pug">
.profile-setting-page.flex.column.gap-30
  .content-container.flex.column.align-center
    .form.flex.column.gap-10
      .row.flex.gap-10
        .flex.column.flex-1.gap-10
          .col-title 姓氏 ( Last Name )
          el-input(disabled, v-model="profile.family_name")
        .flex.column.flex-1.gap-10
          .col-title 名字* ( First Name)
          el-input(disabled, v-model="profile.given_name")
      .warn.flex.column
        .line 若需要變更姓名，請聯絡蛹之生人員
        .line If you need to change your name, please contact customer service.
      .row.flex.column.gap-10.rwd-wrap
        .flex.column.flex-1.gap-10
          .col-title 出生指定性別* ( Assigned at birth gender )
          el-select(placeholder="請選擇性別", v-model="profile.sex")
            el-option(label="男性", value="male")
            el-option(label="女性", value="female")
            el-option(label="其他", value="other")
        .flex.column.flex-1.gap-10
          .col-title 稱謂 ( Salutation )
          el-input(v-model="profile.wanna_be_called")
      .row.flex.column.gap-10.rwd-wrap
        .flex.column.flex-1.gap-10
          .col-title 出生年月日* ( Birthday )
          .input.flex.align-center
            el-date-picker.flex-1(
              placeholder="請選擇日期"
              v-model="profile.birthday")
      .row.flex.column.gap-10.rwd-wrap
        .flex.column.flex-1.gap-10
          .col-title 身分證字號* ( ID number )
          el-input(v-model="profile.id_number" :disabled="!canEditID")
          .warn.flex.column(v-if="!canEditID")
            .line 若需要變更身分證字號，請聯絡蛹之生個管師
            .line If you need to change your ID, please contact customer service.
        .flex.column.flex-1.gap-10
          .col-title 護照號碼* ( Passport )
          el-input(v-model="profile.passport" :disabled="!canEditPassport")
          .warn.flex.column(v-if="!canEditPassport")
            .line 若需要變更護照號碼，請聯絡蛹之生個管師
            .line If you need to change your passport, please contact customer service.
      .row.flex.column.gap-10
        .flex.column.gap-10.left
          .col-title 國碼* ( Code )
          el-select(v-model="phoneCountry"  filterable)
            el-option(
              v-for="country in countryList"
              :key="country.code"
              :value="country.dial_code"
              :label="`${country.code} ${country.dial_code}`"
            )
        .flex.column.flex-1.gap-10
          .col-title  聯絡電話* ( Phone number )
          el-input(v-model="phone")
      .row.flex.column.gap-10
        .flex.column.flex-1.gap-10
          .col-title Email 登入帳號*  ( Email address )
          el-input(disabled v-model="profile.email")
      .warn.flex.column
        .line 若需要變更Email，請聯絡蛹之生個管師
        .line If you need to change your Email, please contact customer service.
      .row.flex.column.gap-10
        .flex.column.flex-1.gap-10
          .col-title 所在國家* (Country)
          el-select(
            filterable
            v-model="profile.country"
            @change="profile.county = ''")
            el-option(
              v-for="country in countryList"
              :key="country.code"
              :value="country.code"
              :label="`${country.flag} ${country.name}`"
            )
      .row.flex.column.gap-10.rwd-wrap
        .flex.column.flex-1.gap-10
          .col-title 縣市* ( City )
          el-input(v-if="profile.country && profile.country !== 'TW'", v-model="profile.county")
          el-select.input(
            v-else
            autocomplete="off"
            filterable
            v-model="profile.county" placeholder="請輸入縣市")
            el-option(
              v-for="county in counties"
              :key="county"
              :label="county"
              :value="county")
        .flex.column.flex-1.gap-10
          .col-title 鄉鎮區* ( Township/ District )
          el-input(v-if="profile.country && profile.country !== 'TW'", v-model="profile.district")
          el-select.input(
            v-else
            filterable
            :disabled="profile.county === undefined || profile.county === ''"
            v-model="profile.district" placeholder="請輸入鄉鎮")
            el-option(
              v-for="d in districts"
              :key="d"
              :label="d"
              :value="d")
      .row.flex.column.gap-10.rwd-wrap
        .flex.column.flex-1.gap-10
          .col-title 地址* ( Address )
          el-input(v-model="profile.address")
      .row.flex.column.gap-10.rwd-wrap
        .flex.column.flex-1.gap-10
          .col-title 感情狀態 ( Marital status )
          relation-select.input(v-model="profile.relationship")
        .flex.column.flex-1.gap-10
          .col-title 最高學歷 ( Education )
          education-select.input(v-model="profile.education")
        .flex.column.flex-1.gap-10
          .col-title 職業 ( Occupation )
          el-input(v-model="profile.job", placeholder="請輸入職業")
      .row.flex.column.gap-10
        .flex.column.flex-1.gap-10
          .col-title 緊急聯絡人*  ( Emergency contact )
          .flex.align-center.gap-10
            el-input(v-model="profile.emergency_person", placeholder="請輸入緊急聯絡人")
            el-input.right(v-model="profile.emergency_relationship", placeholder="請輸入關係")
      .row.flex.column.gap-10
        .flex.column.gap-10.left
          .col-title 國碼* ( Code )
          el-select(v-model="contactPhoneCountry" filterable)
            el-option(
              v-for="country in countryList"
              :key="country.code"
              :value="country.dial_code"
              :label="`${country.code} ${country.dial_code}`"
            )
        .flex.column.flex-1.gap-10
          .col-title  緊急聯絡人聯絡電話* ( Phone number of emergency contact )
          el-input(v-model="contactPhone")
      .row.flex.column.gap-10.rwd-wrap
        el-button.flex-1 取消
        el-button.app-primary.flex-1(type="primary", @click="save") 更新
</template>

<script>
import { mapMutations } from 'vuex';
import { getList } from 'country-list-with-dial-code-and-flag';
import {
  getCounties,
  getDistricts,
} from '@/util/address';
import {
  validateID,
} from '@/util/validator';
import { getProfileWithKey } from '@/api/auth';
import { submitForm } from '@/api/user';

export default {
  data() {
    const countries = getList();
    countries.sort((a, b) => (a.name > b.name ? 1 : -1));
    const idx = countries.findIndex((c) => c.dial_code === '+886');
    const tw = countries[idx];
    tw.name = 'Taiwan';
    countries.splice(idx, 1);
    countries.unshift(tw);
    const counties = getCounties();

    return {
      profile: {},
      counties,
      countryList: countries,

      phone: '',
      phoneCountry: '',
      contactPhone: '',
      contactPhoneCountry: '',
      key: '',
      canEditID: false,
      canEditPassport: false,
    };
  },
  computed: {
    districts() {
      return this.getDistricts(this.profile.county);
    },
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    getDistricts,
    checkInput(data) {
      console.log(data);
      const errs = this.$initInputError();
      if (data.sex === '') {
        errs.add('生理性別', '請輸入生理性別');
      }
      if (!data.birthday) {
        errs.add('生日', '請輸入生日');
      }
      if (data.emergency_contact === ''
      || data.emergency_relationship === ''
      || data.emergency_person === '') {
        errs.add('緊急聯絡人', '緊急聯絡人的關係與電話必須都填寫');
      }
      if (!data.id_number && !data.passport) {
        errs.add('身分證與護照號碼', '身分證與護照號碼至少需填寫一項');
      }
      if (data.id_number !== '' && !validateID(data.id_number)) {
        errs.add('身分證字號', '身分證字號格式錯誤');
      }
      if (!data.phone) {
        errs.add('聯絡電話', '請輸入聯絡電話');
      }
      if (data.country === 'TW' && (!data.county || !data.district)) {
        errs.add('地址', '請輸入地址相關訊息');
      } else if (!data.address) {
        errs.add('地址', '請輸入地址相關訊息');
      }

      if (errs.hasError) {
        const msg = errs.warnMessage;
        this.$showWarn(msg.title, msg.message);
        return false;
      }

      return true;
    },
    async loadData(key) {
      if (key === '') {
        return;
      }
      this.$execWithLoading(async () => {
        const rsp = await getProfileWithKey(key);
        this.profile = {
          ...rsp,
        };
        [this.phoneCountry, this.phone] = this.$splitPhone(rsp.phone);
        [this.contactPhoneCountry, this.contactPhone] = this.$splitPhone(rsp.emergency_contact);
        if (!this.profile.id_number) {
          this.canEditID = true;
        }
        if (!this.profile.passport) {
          this.canEditPassport = true;
        }
      }, (e) => {
        console.log(e);
        this.$showError('載入資料失敗');
        this.$router.push('/login');
      });
    },
    async save() {
      const data = this.generateProfile(this.profile);
      if (!this.checkInput(data)) {
        return;
      }
      this.$execWithLoading(async () => {
        await submitForm(this.key, data);
        this.$showSuccess('更新成功');
        this.$router.push('/login');
      }, (e) => {
        this.$showAxiosException('更新失敗', e);
      });
    },
    generateProfile(profile) {
      return {
        gender: profile.sex,
        wanna_be_called: profile.wanna_be_called,
        birthday: this.$timestampToDate(new Date(profile.birthday)).replace(/\//g, '-'),
        id_number: profile.id_number,
        passport: profile.passport,
        phone: this.$getPhone(this.phoneCountry, this.phone),
        country: profile.country,
        county: profile.county,
        district: profile.district,
        address: profile.address,
        relationship: profile.relationship,
        education: profile.education,
        job: profile.job_title,
        emergency_person: profile.emergency_person,
        emergency_relationship: profile.emergency_relationship,
        emergency_contact: this.$getPhone(this.contactPhoneCountry, this.contactPhone),
        line_chat_id: profile.line_chat_id,
      };
    },
  },
  mounted() {
    const queryString = window.location.hash.replace('#/form', '');
    const urlParams = new URLSearchParams(queryString);
    const key = urlParams.get('key');
    this.key = key;
    this.setUserInfo(undefined);
    this.loadData(key);
  },
};
</script>

<style lang="scss" scoped>
.content-container {
  .form {
    width: 100vw;
    max-width: 590px;
  }
}
.warn {
  color: #E6A23C;
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 169.231% */
}
.row {
  .left {
    flex: 0 0 120px;
  }
  .right {
    flex: 0 0 200px;
  }
}
.app-primary {
  background: #5AB0ACF5;
  color: white;
  border: none;
  &:hover {
    background: #5AB0AC;
  }
  &:active {
    background: #5AB0ACF5;
  }
  &:focus {
    background: #5AB0AC;
  }
}
.profile-setting-page {
  padding: 40px;
}

@media only screen and (max-width: 720px) {
  .profile-setting-page {
    padding: 20px;
    padding-bottom: 80px;
    .form {
      max-width: 100vw;
      padding: 20px;
    }
    .rwd-wrap {
      .flex-1 {
        min-width: 300px;
      }
    }
    .el-button {
      margin-left: 0;
    }
  }
}
</style>
