<template lang="pug">
.reserves.flex.column.p-20.gap-20
  .title.mt-20.font-size-18 我的預約記錄
  .ops.flex.align-center.gap-10
    .switch.future.flex.align-center.gap-10.clickable(
      @click="loadData(true)"
      :class="{'focus': future}")
      i.el-icon-check
      span 未來的預約
    .switch.past.flex.align-center.gap-10.clickable(
      @click="loadData(false)"
      :class="{'focus': !future}")
      i.el-icon-check
      span 過去的預約記錄
  .past-filter(v-if="!future")
    el-dropdown(trigger="click")
      el-button(icon="el-icon-date", type="primary", round) {{ currentYear }}
      el-dropdown-menu
        el-dropdown-item(v-for="year in years", :key="year")
          .dropdown-year.font-size-14(@click="loadYear(year)") {{ year }}
  .card.app-card-style.flex.column.overflow-overlay(v-if="future")
    .record.flex.align-center.pl-20.pr-20(v-if="reserves.length === 0") 無紀錄
    .record.flex.align-center.gap-20.pl-20.pr-20.pt-12.pb-12(
      @click="viewDetail(reserve, idx)"
      v-for="(reserve, idx) in reserves", :key="idx")
      .time-sec.flex.column
        .date {{ $timestampToDateWithDay(reserve.start_at ) }}
        .time.flex.align-start.gap-4
          span.first {{ $timestampToTime(reserve.start_at) }}
          span.second {{ $timestampToM(reserve.start_at) }}
      .info.flex.column.flex-1
        .pos
          span(v-if="reserve.location_type === 'virtual'") 遠距諮商
          span(v-else) {{ reserve.location?.branch?.name }}
        .pro {{ reserve.professional?.title }} {{ reserve.proName }}
        template(v-if="reserve.location_type === 'virtual'")
          .payment.link-text.clickable(v-if="reserve.payment === 'notpaid'") 晤談前請先點選付款
          .payment.success-color(v-else-if="reserve.payment === '3rdparty'") 已完成付款
        template(v-if="reserve.payment === 'project'")
          .payment.success-color 第三方已付款
      .img.flex.align-center.justify-center
        img.avatar(:src="reserve.professional.avatar")
  template(v-else-if="yearReserves.length > 0")
    template(v-for="monthReserves in yearReserves")
      .single.flex.column.align-start.gap-10
        .tag-container.flex.column.align-center
          el-tag(type="info", size="small") {{ monthReserves.month }}月
        .card.app-card-style.flex.column.overflow-overlay
          .record.flex.align-center.pl-20.pr-20(v-if="reserves.length === 0") 無紀錄
          .record.flex.align-center.gap-20.pl-20.pr-20.pt-12.pb-12(
            @click="viewDetail(reserve, idx)"
            v-for="(reserve, idx) in monthReserves.data", :key="idx")
            .time-sec.flex.column
              .date {{ $timestampToDateWithDay(reserve.start_at ) }}
              .time.flex.align-start.gap-4
                span.first {{ $timestampToTime(reserve.start_at) }}
                span.second {{ $timestampToM(reserve.start_at) }}
            .info.flex.column.flex-1
              .pos {{ reserve.location?.branch?.name }}
              .pro {{ reserve.professional?.title || '專業人士' }} {{ reserve.proName }}
            .img.flex.align-center.justify-center
              img.avatar(:src="reserve.professional.avatar")
  template(v-else)
    .card.app-card-style.flex.column.overflow-overlay
      .record.flex.align-center.pl-20.pr-20 無紀錄
  viewer(
    ref="viewer"
    @update="updateRow")
</template>

<script>
import { getAppointments } from '@/api/reserve';
import Viewer from '../components/drawer/ReserveView.vue';

export default {
  components: {
    viewer: Viewer,
  },
  data() {
    const now = new Date();
    const latestYear = now.getFullYear();
    const years = [];
    for (let i = 0; i < 5; i += 1) {
      years.push(latestYear - i);
    }
    return {
      future: true,
      reserves: [],
      years,
      latestYear,
      currentYear: latestYear,
      yearReserves: [],
      showIdx: undefined,
    };
  },
  methods: {
    loadYear(year) {
      this.currentYear = year;
      this.loadData(false);
    },
    getFilter() {
      const now = new Date();
      const nowTs = parseInt(now.getTime() / 1000, 10);
      if (this.future) {
        return {
          start_at: nowTs - 3 * 3600,
        };
      }
      const yearStart = new Date();
      yearStart.setFullYear(this.currentYear, 0, 1);
      yearStart.setHours(0, 0, 0, 0);
      const yearEnd = new Date();
      yearEnd.setFullYear(this.currentYear + 1, 0, 1);
      yearEnd.setHours(0, 0, 0, 0);
      return {
        start_at: parseInt(yearStart.getTime() / 1000, 10),
        end_at: Math.min(nowTs, parseInt(yearEnd.getTime() / 1000, 10)),
      };
    },
    viewDetail(reserve, idx) {
      this.$refs.viewer.$emit('show', reserve);
      this.showIdx = idx;
    },
    loadData(future) {
      if (future !== this.future) {
        this.future = future;
        const now = new Date();
        const latestYear = now.getFullYear();
        this.currentYear = latestYear;
      }
      this.$execWithLoading(async () => {
        await this.loadReserves(this.getFilter());
      });
    },
    async loadReserves(filter) {
      const data = await getAppointments(filter);
      const reserves = data.map((d) => ({
        ...d,
        proName: `${d.professional?.family_name}${d.professional?.given_name}`,
        showPay: d.location_type !== 'inhouse' && d.payment === 'notpaid',
      }));
      const nowTs = parseInt((new Date()).getTime() / 1000, 10);
      if (this.future) {
        this.reserves = reserves.filter((r) => r.end_at > nowTs);
        return;
      }
      this.reserves = reserves.filter((r) => r.end_at < nowTs);
      this.reserves.sort((r1, r2) => (r1.start_at - r2.start_at));
      const yearReserves = [];
      const reserveMap = {};
      this.reserves.forEach((r) => {
        const start = new Date(r.start_at * 1000);
        const month = start.getMonth() + 1;
        if (!reserveMap[month]) {
          reserveMap[month] = [];
        }
        reserveMap[month].push(r);
      });
      for (let i = 12; i > 0; i -= 1) {
        if (reserveMap[i]) {
          reserveMap[i].reverse();
          yearReserves.push({
            month: i,
            data: reserveMap[i],
          });
        }
      }
      this.yearReserves = yearReserves;
    },
    async updateRow(reserve) {
      console.log(reserve);
      this.reserves[this.showIdx].payment = reserve.payment;
      this.$forceUpdate();
    },
  },
  mounted() {
    this.loadData(true);
  },
};
</script>

<style lang="scss" scoped>
.reserves {
  .switch {
    background: #B5AAA6;
    border-radius: 1000px;
    color: white;
    padding: 3px 15px 3px 15px;
    i {
      display: none;
    }
    &.focus {
      background: rgba(126, 108, 100, 0.96);
      i {
        display: block;
      }
    }
  }
  .past-filter {
  }
  .tag-container {
    width: 100%;
    max-width: 590px;
  }
  .card {
    width: 100%;
    max-width: 590px;
    background: white;
    .record {
      flex: 0 0 74px;
      .time-sec {
        .date {
          color: #909399;
        }
        .time {
          color: #5AB0AC;
          .first {
            font-weight: bold;
            font-size: 28px;
          }
          .second {
            margin-top: 4px;
          }
        }
      }
      .info {
        font-size: 13px;
        .pos {
          color: #131414;
          flex: 22px;
        }
        .pro {
          color: #606266;
          flex: 22px;
        }
      }
      .img {
        flex: 0 0 50px;
        border-radius: 25px;
        overflow: hidden;
        img {
          height: 50px;
          width: 50px;
        }
      }
    }
  }
}

.dropdown-year {
  padding: 8px 20px;
  min-width: 150px;
}
</style>
