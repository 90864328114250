<template lang="pug">
.app-button.flex.align-center.justify-center.clickable(:class="btnClass")
  slot
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    btnClass() {
      return [this.type];
    },
  },
};
</script>

<style lang="scss" scoped>
.app-button {
  padding: 8px 20px;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  &.primary {
    background: rgba(90, 176, 172, 0.96);
    color: white;
  }
}
</style>
