import instance from './base';

export async function getActivities() {
  const rsp = await instance.get('/v1/activities/popular');
  return rsp.data;
}

export async function getArticles() {
  const rsp = await instance.get('/v1/articles?popular=true&page=0&limit=6');
  return rsp.data;
}

export async function getCourses() {
  const rsp = await instance.get('/v1/courses?popular=true&page=0&limit=6');
  return rsp.data;
}

export async function customSearch(keyword) {
  const rsp = await instance.get(`/v1/customsearch?&q=${keyword}&start=1`);
  return rsp.data;
}
