<template lang="pug">
.redirect-page
</template>

<script>
import { mapMutations } from 'vuex';
import { bindLINE, loginLINE } from '@/site/app/api/line';
import { getProfile } from '@/api/auth';

export default {
  methods: {
    ...mapMutations(['setUserInfo', 'setIsLINELogin']),
    handleCode() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code');
      const state = urlParams.get('state');
      if (!code) {
        this.$router.push('/home');
      }
      if (state === 'bind') {
        this.$execWithLoading(async () => {
          await bindLINE(code);
          this.$showSuccess('綁定成功');
          this.$router.push('/setting');
          this.setIsLINELogin(true);
        }, (e) => {
          this.$showAxiosException('綁定失敗', e);
          this.$router.push('/login');
        });
      } else if (state === 'login') {
        this.$execWithLoading(async () => {
          await loginLINE(code);
          const profile = await getProfile();
          this.setUserInfo(profile);
          this.$router.push({ name: 'Reserves' });
          this.setIsLINELogin(true);
        }, (e) => {
          console.log(e);
          this.$showError('該 LINE 帳號尚未綁定, 請重新使用帳號密碼登入');
          this.$router.push('/login');
        });
      }
    },
  },
  mounted() {
    this.handleCode();
  },
};
</script>

<style lang="scss" scoped>

</style>
