<template lang="pug">
.rule.flex.column.p-40.gap-20
  i.el-icon-arrow-left.clickable(@click="$router.back()") 返回
  .content.flex.column
    .rule-title {{ title }}
    .content.white-space-pre {{ content }}
</template>

<script>
export default {
  data() {
    return {
      title: '一般及醫療使用隱私權政策',
      content: `
最新更新日期：2023.12.04
最新版本生效日期：2023.12.04

蛹之生預約系統（下稱「本系統」）為蛹之生心理諮商所（下稱「蛹之生」）所創設並維護之心理健康暨諮詢預約平台。
關於蛹之生之基本資訊，請參酌「蛹之生心理諮商所」官網https://www.morph.com.tw。
為尊重您的「隱私權」及「醫療隱私權」，蛹之生致力於保護您的個人資料及「敏感性個人資料」（係指個人資料保護法第 6 條所稱病歷、醫療、基因、性生活、健康檢查之個人資料， 以及您因使用本系統進行預約而由醫療人士製作之醫療紀錄），請您於使用本系統服務前詳細閱讀隱私權政策（下稱「本政策」），並請您隨時注意本系統所公告之相關修改或變更，蛹之生有權於任何時間修改或變更本政策之內容。
請您注意，當您使用本系統進行服務預約時或於本政策有任何修改或變更後，而仍繼續使用本系統，即表示您已閱讀、瞭解並同意接受本政策或其修改或變更後之內容， 並同意蛹之生依據本政策取得、蒐集您的個人資料； 如果您無法遵守或不同意本政策之內容，或您所屬的國家、地區排除本政策內容之全部或一部分時，請您立即停止使用本系統。
此外，本系統為醫療機構之醫務管理平台， 蛹之生無權調閱您的敏感性個人資料，蛹之生絕對無法將您的敏感性個人資料提供予任何第三方。蛹之生聲明且擔保，您於使用本系統進行諮商預約而由醫療人士依法製作之紀錄，除了為您進行服務預約之執業醫療人士外， 絕無第三人除您親自授權外擁有合法權利能蒐集、處理或利用您的敏感性個人資料。
本系統可能於臺灣以外之地區蒐集、處理、利用您的個人資料，且將受到實體及技術安全等裝置的保護，若您反對此蒐集、處理、利用之方式，亦請您立即停止使用本系統。 若您為未滿18歲或無完全行為能力之人，除應符合上述規定外， 請於您的法定代理人或監護人閱讀、瞭解並同意本政策及其後修改、變更後之所有內容後，方得使用或繼續使用本系統，否則請立即停止使用本系統。當您使用或繼續使用本系統時，即表示您的法定代理人或監護人已閱讀、瞭解並同意接受本政策及其後修改、變更之所有內容。
1. 本政策適用範圍：本政策適用於您使用本系統所提供的各項服務時，有關個人資料的蒐集、利用及保護， 但不適用於與本系統連結之其他網站或應用程式。凡經由本系統連結的其他網站或應用程式， 均有其專屬的隱私權保護政策，蛹之生並不負任何連帶責任。 當您連結到這些應用服務時，關於個人資料的保護，應適用各該應用服務的隱私權保護政策。
2. 本政策所指之個人資料：
  (1) 當您使用本系統之服務，蛹之生會蒐集您下列類別的個人資料，如： 
    ● Ｃ00一：辨識個人者（例如：姓名、職稱、住址、聯絡電話、E-mail）、
    ● Ｃ00三：政府資料中之辨識者（例如：身分證統一編號）、 
    ● Ｃ0一一：個人描述（例如：年齡、出生年月日、性別、國籍）、
    ●  Ｃ0二一：家庭情形（例如：結婚有無、配偶之姓名）、
    ●  Ｃ0三四：旅行及其他遷徙細節（例如：過去之遷徙、旅行細節、外國護照、居留證明文件及工作證照及工作證等相關細節）、 
    ● Ｃ0三八：職業、 ，及其他任何得以直接或間接方式識別您個人之資料。
  請注意！在非醫療相關服務範疇使用本系統並不會、處理、利用或國際傳輸您有關病歷、醫療、基因、性生活、健康檢查等敏感性個人資料。
  (2) 當您使用本系統所提供「預約」服務時 （包括但不限於諮詢預約、通訊診療以及其他中央主管機關特許之醫療行為）， 蛹之生因作為醫療機構之醫務管理平台，將協助醫療機構、醫療人士蒐集、處理您下列類別的「敏感性」個人資料，如： 
    ● Ｃ一一一：健康紀錄。（例如：醫療報告、治療與診斷紀錄、檢驗結果、身心障礙種類、等級、 有效期間、身心障礙手冊證號及聯絡人等）、
    ● Ｃ一一二：性生活、
    ● Ｃ一一三：種族或血統來源。 （例如：去氧核糖核酸資料等）。
    ● 惟蛹之生除依法絕無調閱、利用您的敏感性個人資料之權利外， 現實上，依照蛹之生就儲存敏感性個人資料所為系統設置，除與您進行服務預約之執業醫療人士外， 蛹之生絕無權限調閱、利用您的敏感性個人資料。
3. 個人資料之蒐集、處理：當您同意使用本系統協助安排心理諮商預約時，即表示您同意且授權承攬蛹之生個案管理業務人員包含但不限於個案管理師、系統管理團隊者可蒐集、處理或利用您使用本系統所產生之相關紀錄，用以最佳化服務流程。當您在本系統進行預約服務時， 所留下的個人資料包括但不限於姓名、電子郵件地址、手機號碼、 由醫療人士依法應製作之醫療報告、治療與診斷紀錄……等，蛹之生將妥當保存您的醫療資訊等敏感性個人資料。個人資料之蒐集、處理、利用或國際傳輸： 您瞭解並同意蛹之生依本政策及相關法規，以符合法令之自動化及人工方式蒐集、 處理、利用或國際傳輸您所提供的個人資料。
4. 不提供個人資料之影響：您可自由選擇是否提供您上述的個人資料，但當您不同意提供時，您將無法享有蛹之生藉由本系統提供之服務。凡您使用本系統上之服務，蛹之生可能會透過電子郵件傳送您在本系統的預約資訊。您可選擇是否接收電子郵件， 但當您不同意接收時，您將無法享有蛹之生提供之服務。
5. 蒐集之特定目的：蛹之生蒐集您的個人資料係為利用於：
  ● 0一二：公共衛生或傳染病防治、
  ● 0六四：保健醫療服務、
  ● 0六九：契約、類似契約或其他法律關係事務、
  ● 0八一：個人資料之合法交易業務、
  ● 0九0：消費者、客戶管理與服務、
  ● 0九一：消費者保護、
  ● 0九八：商業與技術資訊、
  ● 一三五：資訊服務、
  ● 一三六：資訊與資料庫管理、
  ● 一五七：調查、統計與研究分析、
  ● 一七六：其他自然人基於正當性目的所進行個人資料之蒐集處理及利用、
  ● 一八一：其他經營合於營業登記項目或組織章程所定之業務、
  ● 一八二：其他諮詢與顧問服務。
6. 您對個人資料之使用權利皆須經由向實施醫療業務機構申請， 在不違反相關法律的情況下，於該實施醫療業務機構確認個人資料為您本人所有後， 利用本司提供之醫務管理平台協助您行使下列之權利：(1) 查詢、閱覽、補充或更正您的個人資料；(2) 停止蒐集、處理或利用您的個人資料；(3) 刪除您的個人資料；(4) 製給您個人資料的副本一份。
7. 個人資料利用之期間：蛹之生將在上述取得您個人資料目的之存續期間內或其他法令允許之期間內，處理、利用或國際傳輸您的個人資料。
8. 個人資料利用之地區：蛹之生之個人資料主機、相關網路伺服器主機所在地及蛹之生相關服務之行銷地區，為您個人資料利用之地區。
9. 個人資料利用之對象：蛹之生、蛹之生之關係企業、蛹之生之合作夥伴、 相關委外之協力廠商與平台上相關醫療品牌及政府機關，為您個人資料利用之對象。
10. 您對個人資料之權利：在不違反相關法律的情況下，於蛹之生確認該個人資料為您本人所有後，您可以聯絡蛹之生或將您的需求郵寄至蛹之生的電子郵件信箱（reservation@morph.tw），以行使下列之權利：(1) 查詢、閱覽、補充或更正您的個人資料；(2) 停止蒐集、處理或利用您的個人資料；(3) 刪除您的個人資料；(4) 製給您個人資料的副本一份；或 (5) 停止寄發給您本系統相關廣告／行銷活動。但請注意，根據醫療法，您若已進行心理諮商等醫療行為，相關的醫療紀錄（含個人基本資料）須保存7年。
11. 除下列情形外，蛹之生不會任意將您的個人資料揭露予前述第十點以外之任何人：
蛹之生將因法律規定、法院命令、行政調查或其他法律程序的要求而提供您的資料，惟在此情形下，該資料只會單純提供予調查單位，並受中華民國相關法律的保護；
  ● 蛹之生將因您使用本系統支付平台相關活動項目時，將您同意提供之財務資訊（包括但不限於金融機構帳戶之號碼與姓名、或信用卡卡號、安全碼、到期日）提供予特定符合 PCIDSS 認證之第三方數位金流服務提供者；
  ● 蛹之生事前或事後（在情境滿足上述 A 或 B 或 C 之要點）向您取得同意。
12. 蛹之生有權隨時視實際情形修訂本政策，本政策有重大變更時，蛹之生將刊登公告於本系統官方網站（本系統官方網站連結：https://app.morph.tw），您有義務隨時注意本政策有無更新或修訂。如您不同意所變更之內容，得隨時選擇停止使用本系統之服務，如您繼續使用本系統，即表示您同意本政策變更之內容。
13. 個人資料之安全：蛹之生將依相關法律規定，採取適當的技術性及組織性等安全措施，保護您所提供的個人資料，並限於因業務需要必須接觸您個人資料的員工，始有權限接觸到您的個人資料。蛹之生已採取符合法規要求的實體、電子和程序防護措施，以保護您個人資料的安全。還請留意，蛹之生定將盡力保護您的個人資料，惟仍無法保證您的個人資料不為第三人非法蒐集、處理或利用。
14. 如何聯繫我們
蛹之生官方網站：https://www.morph.com.tw
蛹之生LINE官方帳號： @morphtw
客服信箱：reservation@morph.tw
`,
    };
  },
};
</script>

<style lang="scss" scoped>
.rule-title {
  font-size: 20px;
  color: #295294;
}
</style>
